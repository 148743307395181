<template>
  <transition
    v-show="btnAnimation"
    :name="animationName"
  >
    <button
      class="aog-button"
      type="button"
      @click.prevent="openPopup"
    >
      <slot name="name">{{ this.$store.state.navigationData?.AOGButtonText }}</slot>
    </button>
  </transition>

  <teleport to="#popups">
    <transition name="fade-slide-up">
      <popup
        v-if="popupActive"
        :isShown="popupActive"
        @modal-closed="popupActive = false"
      >
        <template #content>
          <aog></aog>
        </template>
      </popup>
    </transition>
  </teleport>
</template>

<script>
import Popup from '../../Popup.vue';
import Aog from '../../Aog.vue';

export default {
  name: 'AogButton',
  components: { Popup, Aog },
  emits: ['hideNav'],
  props: {
    btnAnimation: {
      type: Boolean,
      default: true,
    },
    animationName: {
      type: String,
      default: 'fade',
    },
  },
  data() {
    return {
      popupActive: false,
    };
  },
  methods: {
    openPopup() {
      this.popupActive = true;
      this.$emit('hideNav');
    },
  },
};
</script>

<style lang="scss">
.aog-button {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  border: 3px solid currentColor;
  line-height: 1;
  box-sizing: border-box;
  color: currentColor;

  @include wRule(
    margin-right,
    (
      xl: 52,
      lg: 42,
      md: 36,
      sm: 50,
    )
  );

  @include wRule(
    border-width,
    (
      xl: 3,
      lg: 3,
      md: 3,
      sm: 3,
    )
  );

  @include wRule(
    width,
    (
      xl: 185,
      lg: 156,
      md: 152,
      sm: 100%,
    )
  );
  @include wRule(
    height,
    (
      xl: 52,
      lg: 44,
      md: 44,
      sm: 93,
    )
  );

  @include wRule(
    font-size,
    (
      xl: 20,
      lg: 18,
      md: 15,
      sm: 45,
    )
  );

  &::before {
    @include pseudo;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: currentColor;
    // filter: invert(100%);
    opacity: 0;
    transition: opacity $ease-main;
    z-index: -1;
  }

  &:hover {
    @include screen("sm", min) {
      &::before {
        opacity: 0.1;
      }
    }
  }

  &.slide-fade-enter-active {
    transition: all 0.5s ease;
    transition-delay: 0.35s;
  }

  &.slide-fade-leave-active {
    transition: all 0.3s ease;
  }

  &.slide-fade-enter-from,
  &.slide-fade-leave-to {
    opacity: 0;
    transform: translate3d(100px, 0, 0);
  }

  &.scalein-enter-active {
    transition: all 0.2s ease;
    transition-delay: 0.25s;
  }

  &.scalein-leave-active {
    transition: all 0.2s ease;
  }

  &.scalein-enter-from,
  &.scalein-leave-to {
    transform: scale(0);
  }

  &.fade-enter-active {
    transition-delay: 0.25s;
  }
}
</style>
