<template>
  <page-header
    :is-slider="isSlider"
    :dark-theme="darkHeaderTheme"
    :isFooterReached="sliderReachFooter"
  ></page-header>
  <main class="page">
    <router-view v-slot="{ Component }">
      <transition name="fade">
        <component
          :is="Component"
          @page-slider-scroll-down="pageScrollDown"
          @page-slider-scroll-up="pageScrollUp"
          @page-slider-reach-footer="pageSliderReachFooter"
          @page-slider-go-away-footer="pageSliderGoAwayFromFooter"
        />
      </transition>
    </router-view>
  </main>
  <page-footer v-if="!noFooter"></page-footer>
</template>

<script>
import PageHeader from './components/PageHeader/PageHeader.vue';
import PageFooter from './components/PageFooter.vue';
import breakpointWatcher from './tools/breakpointsWatcher';
import api from '@/tools/api';

export default {
  components: { PageHeader, PageFooter },
  name: 'home',
  data() {
    return {
      isSlider: false,
      noFooter: true,
      pageSliderOnTop: true,
      pageSliderMoveDown: false,
      darkHeaderTheme: false,
      sliderReachFooter: false,
    };
  },
  watch: {
    $route(route) {
      this.noFooter = route.name === 'Main' || route.name === 'About';
      this.isSlider = route.name === 'Main' || route.name === 'About';
      this.pageSliderOnTop = true;
      this.pageSliderMoveDown = false;
      this.darkHeaderTheme = false;
      this.sliderReachFooter = false;
    },
    currentLang(lang) {
      localStorage.setItem('lang', lang);
      this.getNavigation();
    },
  },
  methods: {
    pageScrollDown(slideNumber) {
      if (this.$route.name === 'About') {
        this.darkHeaderTheme = slideNumber === 1;
      }
    },
    pageScrollUp(slideNumber) {
      if (this.$route.name === 'About') {
        this.darkHeaderTheme = slideNumber === 1;
      }
    },
    pageSliderReachFooter() {
      const route = this.$route.name;
      if (route === 'About' || route === 'Main') {
        this.sliderReachFooter = true;
      }
    },
    pageSliderGoAwayFromFooter() {
      const route = this.$route.name;
      if (route === 'About' || route === 'Main') {
        this.sliderReachFooter = false;
      }
    },
    setLanguage() {
      // const lang = localStorage.getItem('lang') ?? 'ru';
      const lang = 'ru';
      localStorage.setItem('lang', lang);
      this.$store.dispatch('setLang', lang);
    },
    async getContacts() {
      const contacts = await api('basic-contacts');
      this.$store.commit('setContacts', [
        {
          icon: 'phone',
          href: `tel:${contacts.Phone.replace(/ /gi, '')}`,
          name: contacts.Phone,
        },
        {
          icon: 'mail',
          href: `mailto:${contacts.Email}`,
          name: contacts.Email,
        },
      ]);
    },
    async getNavigation() {
      const navigation = await api('navigation', true);
      this.$store.commit('setNavigation', navigation);
      // eslint-disable-next-line operator-linebreak
      document.title = `${this.navigationData?.[`NamePage${this.$route.name}`] ||
        this.$route.name} | СКАЙПРО Техникс`;
    },
    async getSocials() {
      const socials = await api('socials');
      this.$store.commit(
        'setSocials',
        socials.map((social) => ({
          icon: social?.Name.toLowerCase(),
          href: social?.Link,
        })),
      );
    },
    async getNavigationColors() {
      const colors = await api('navigation-colors');
      this.$store.commit('setNavigationColors', colors);
    },
    async setRoutesNames() {
      // const routes = this.$router.getRoutes();
      console.log(this.$router);
    },
  },
  computed: {
    currentLang() {
      return this.$store.getters.getLang;
    },
    navigationData() {
      return this.$store.getters.getNavigationData;
    },
  },
  beforeMount() {
    this.setLanguage();
    this.getContacts();
    this.getNavigation();
    this.getSocials();
    this.setRoutesNames();
    this.getNavigationColors();
  },
  mounted() {
    breakpointWatcher(this);
  },
};
</script>

<style lang="scss" src="./styles/index.scss"></style>
