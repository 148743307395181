<template>
  <div
    class="slider-home-bullets"
    :class="{'is-animated': inView}"
    v-observe-visibility="{callback: animation, once: true}"
  >
    <div
      class='slider-home-bullet'
      v-for="(bullet, bulletIndex) in bulletsArray"
      :key="`bullet-${bulletIndex}`"
      :class="[{'is-active': currentActive === `slide-section-${bulletIndex}`}]"
      v-touch:tap="scroll(bulletIndex)"
    >{{bullet}}</div>
  </div>
</template>

<script>
import { animationMixin } from '@/components/mixins/animation';
import smoothScrollToAnchor from '@/tools/smoothScrollToAnchor';

export default {
  name: 'SliderHomeBullets',
  mixins: [animationMixin],
  props: {
    bullets: {
      type: Array,
    },
    currentSection: {
      type: [String, Number],
      default: 'slide-section-0',
    },
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
    currentActive() {
      return this.currentSection;
    },
    bulletsArray() {
      const bull = this.bullets.slice(0, -1);
      return bull;
    },
  },
  methods: {
    scroll(id) {
      // eslint-disable-next-line no-unused-vars
      return (_direction, _event) => {
        const target = document.getElementById(`slide-section-${id}`);
        if (target) {
          smoothScrollToAnchor(target);
        }
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.slider-home-bullets {
  position: fixed;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  z-index: 12;
  transform-origin: 0 0;
  bottom: 0;
  opacity: 0;
  transform: rotate(-90deg) translate3d(-20px, 0, 0);
  transition: transform 0.3s ease, opacity 0.3s ease, color 0.3s ease;

  @include screen("sm", max) {
    display: none;
  }
  @include wRule(
    height,
    (
      xl: 25,
      lg: 25,
      md: 25,
      sm: 0,
    )
  );
  @include wRule(
    left,
    (
      xl: 100,
      lg: 68,
      md: 47,
      sm: 0,
    )
  );
  @include wRule(
    bottom,
    (
      xl: 160,
      lg: 98,
      md: 64,
      sm: 0,
    )
  );

  @include screen("sm", max) {
    display: none;
  }

  &.is-animated {
    opacity: 1;
    transform: rotate(-90deg) translate3d(0, 0, 0);
    transition-delay: 0.5s;
  }
}

.slider-home-bullet {
  display: inline-block;
  border-radius: 0;
  background-color: currentColor;
  width: auto;
  font-size: 0;
  transition: background-color 0.25s ease, font-size 0.5s ease, color 0.3s ease;
  overflow: hidden;
  letter-spacing: normal;
  cursor: default;
  color: color(basic-light);

  @include wRule(
    min-height,
    (
      xl: 2,
      lg: 2,
      md: 2,
      sm: 2,
    )
  );
  @include wRule(
    min-width,
    (
      xl: 14,
      lg: 19,
      md: 14,
      sm: 16,
    )
  );

  &:not(:last-child) {
    @include wRule(
      margin-left,
      (
        xl: 8,
        lg: 10,
        md: 8,
        sm: 8,
      )
    );
  }

  &.is-active {
    background-color: transparent;
    width: auto;
    cursor: default;

    @include wRule(
      font-size,
      (
        xl: 16,
        lg: 14,
        md: 12,
        sm: 24,
      )
    );
  }

  &.is-dark {
    color: color(basic);
  }
}
</style>
