import VueScrollTo from 'vue-scrollto';

export default function smoothScrollToAnchor(element) {
  if (!element) return;

  const header = document.querySelector('.header');
  const stickyNavigation = document.querySelector('.side-navigation .link');
  const stickySizes = stickyNavigation.getBoundingClientRect();
  const headerSizes = header.getBoundingClientRect();
  const isSm = window.matchMedia('(max-width: 767px)').matches;
  const shiftDown = isSm ? stickySizes.height : 0;
  const shiftUp = isSm ? stickySizes.height + headerSizes.height : 0;
  const elementTop = element.getBoundingClientRect().top;
  const offset = elementTop < 0 ? -shiftUp : -shiftDown;

  VueScrollTo.scrollTo(element, 500, {
    offset,
    easing: 'ease',
  });
}
