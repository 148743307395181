<template>
  <header
    :class="['header',
  { 'header_light': !isDark}, {'is-scrolled': pageMoveDown || isFooterReached},
  {'is-short': !pageMoveDown && !pagePositionTop}]"
    v-observe-visibility="animation"
  >
    <component
      :is="isHome ? 'div' : 'router-link'"
      :to="isHome ? undefined : '/'"
      class="logo"
    >
      <icon name="logo" />
    </component>
    <div class="header__links">
      <aog-button v-if="breakpoint !== 'sm'">AOG</aog-button>
      <!-- <lang-button v-if="breakpoint !== 'sm'"/> -->
      <!-- <socials v-if="breakpoint !== 'sm'" /> -->
      <button
        class="burger"
        @click="showNavigation"
      >
        <icon name="burger" />
      </button>
    </div>
  </header>
  <transition
    name="slide"
    @after-enter="endNavAnimation"
    @before-leave="endNavAnimation"
  >
    <site-navigation
      v-show="navigationActive"
      :isActive="navigationActive"
      :isShown="navAnimation"
      @hide-navigation="
      hideNavigation"
    />
  </transition>
</template>

<script>
import Icon from '../Icon.vue';
import Socials from '../Socials.vue';
import LangButton from './components/LangButton.vue';
import SiteNavigation from '../SiteNavigation.vue';
import AogButton from './components/AogButton.vue';
import scrollObserver from '../../tools/scrollObserver';
import { animationMixin } from '@/components/mixins/animation';

export default {
  components: {
    Icon,
    Socials,
    LangButton,
    SiteNavigation,
    AogButton,
  },
  mixins: [animationMixin],
  name: 'page-header',
  props: {
    isSlider: {
      type: Boolean,
      defaul: false,
    },
    darkTheme: {
      type: Boolean,
    },
    isFooterReached: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isHome: true,
      navigationActive: false,
      navAnimation: false,
      pageOnTop: true,
      pageScrolledDown: false,
    };
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
    pagePositionTop() {
      return this.pageOnTop;
    },
    pageMoveDown() {
      return this.pageScrolledDown;
    },
    navColor() {
      return this.$store.getters.getNavigationColors;
    },
    isBlack() {
      return this.navColor[this.$route.name];
    },
    isDark() {
      return this.isBlack || this.darkTheme;
    },
  },
  watch: {
    $route(route) {
      this.isHome = route.name === 'Main';
      this.pageMoveDown = false;
      this.pagePositionTop = true;
    },
  },
  methods: {
    showNavigation() {
      this.navigationActive = true;
    },
    hideNavigation() {
      this.navigationActive = false;
    },
    endNavAnimation() {
      this.navAnimation = !this.navAnimation;
    },
  },
  mounted() {
    scrollObserver(this);
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: color(basic);
  box-sizing: border-box;
  transition: transform 0.5s ease, background-color 0.2s ease, padding 0.5s ease;
  will-change: transform, background, padding;
  z-index: 20;
  max-width: 100vw;
  overflow: hidden;

  &.is-animated {
    animation: slideDown 0.8s ease;
  }

  @include rule(
    background-color,
    (
      xl: transparent,
      sm: color(basic-light),
    )
  );

  @include wRule(
    padding,
    (
      xl: 42 104,
      lg: 30 74,
      md: 42 55,
      sm: 23 44,
    )
  );

  &.animation {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  &.is-animated {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }

  &__links {
    display: flex;
    justify-content: flex;
    align-items: center;
  }

  &_light {
    color: color(basic-light);

    @include screen("sm", max) {
      color: color(basic);
      background-color: color(basic-light);
    }
  }

  .socials {
    @include screen("sm", max) {
      display: none;
    }
  }

  &.is-scrolled {
    transform: translate3d(0, -100%, 0);
  }

  &.is-short {
    @include screen("sm", min) {
      background-color: color(basic-light);
      color: color(basic);

      @include wRule(
        padding,
        (
          xl: 14 104,
          lg: 18 74,
          md: 18 55,
          sm: 23 44,
        )
      );
    }
  }
}

.logo {
  display: block;
  flex: none;

  @include wRule(
    height,
    (
      xl: 52,
      lg: 44,
      md: 44,
      sm: 47,
    )
  );

  // @include wRule(
  //   width,
  //   (
  //     xl: 366,
  //     lg: 308,
  //     md: 308,
  //     sm: 338,
  //   )
  // );

  svg {
    height: 100%;
    // width: 100%;
  }
}

.burger {
  display: block;
  color: inherit;
  transition: opacity $ease-main;

  @include wRule(
    width,
    (
      xl: 44,
      lg: 36,
      md: 36,
      sm: 48,
    )
  );
  @include wRule(
    height,
    (
      xl: 32,
      lg: 26,
      md: 26,
      sm: 34,
    )
  );

  &:not(:first-child) {
    @include wRule(
      margin-left,
      (
        xl: 57,
        lg: 45,
        md: 43,
        sm: 44,
      )
    );
  }

  svg {
    height: 100%;
    width: 100%;
  }

  &:hover {
    @include screen("sm", min) {
      opacity: 0.8;
    }
  }
}
</style>
