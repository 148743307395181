<template>
  <div
    class="slider-navigation animation"
    :class="{'dark': isDark, 'white': isWhite, 'is-counter': counter }"
    v-observe-visibility="animation"
  >
    <button
      class="prev"
      type="button"
      :disabled="swiper?.isBeginning"
      @click="prev"
    >
      Next
      <icon name="back" />
    </button>
    <div
      class="counter"
      v-if="counter"
      :key="counterText"
    >{{counterText}}</div>
    <button
      class="next"
      type="button"
      :disabled="swiper?.isEnd"
      @click="next"
    >
      Previous
      <icon name="next" />
    </button>
  </div>
</template>

<script>
import Icon from './Icon.vue';
import { animationMixin } from '@/components/mixins/animation';

export default {
  name: 'SliderNavigation',
  mixins: [animationMixin],
  props: {
    isDark: {
      type: Boolean,
      default: false,
    },
    isWhite: {
      type: Boolean,
      default: false,
    },
    swiper: {
      // eslint-disable-next-line no-undef
      type: Object,
      required: true,
    },
    counter: {
      type: Boolean,
      default: false,
    },
    counterText: {
      type: String,
    },
  },
  components: {
    Icon,
  },
  methods: {
    next() {
      this.swiper.slideNext();
    },
    prev() {
      this.swiper.slidePrev();
    },
  },
};
</script>

<style lang="scss" scoped>
.slider-navigation {
  display: flex;
  align-items: center;
  color: color(bg);

  .next,
  .prev {
    display: block;
    transform-origin: 50% 50%;
    color: inherit;
    transition: color $ease-main, opacity $ease-main;
    font-size: 0;
    line-height: 0;

    @include wRule(
      width,
      (
        xl: 43,
        lg: 31,
        md: 32,
        sm: 43,
      )
    );

    @include wRule(
      height,
      (
        xl: 43,
        lg: 31,
        md: 32,
        sm: 43,
      )
    );

    svg {
      width: 100%;
      height: 100%;
    }

    &:hover {
      @include screen("sm", min) {
        color: color(main);
      }
    }

    &[disabled] {
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
    }
  }

  .prev {
    @include wRule(
      margin-right,
      (
        xl: 40,
        lg: 25,
        md: 50,
        sm: 68,
      )
    );
  }

  .counter {
    font-weight: normal;
    line-height: 1.4;
    white-space: nowrap;

    @include wRule(
      font-size,
      (
        xl: 18,
        lg: 18,
        md: 16,
        sm: 28,
      )
    );
    @include wRule(
      margin,
      (
        xl: 0 15,
        lg: 0 16,
        md: 0 10,
        sm: 0 20,
      )
    );
  }

  &.is-counter {
    .prev {
      margin-right: 0 !important;
    }
  }

  &.dark {
    color: color(basic);

    .next,
    .prev {
      &[disabled] {
        opacity: 0.2;
      }
    }
  }

  &.white {
    color: color(basic-light);
  }

  &.animation {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  &.is-animated {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 0.5s ease 0.7s, transform 0.5s ease 0.7s;
  }
}
</style>
