<template>
  <footer
    class="footer"
    :class="{ 'has-top-margin': $route?.meta?.footerMargin, 'pt0': $route?.meta?.noFooterTop }"
  >
    <div class="container-wide">
      <div class="wrapper">
        <!-- Top -->
        <div class="top">
          <router-link
            to="/"
            class="logo animation"
            v-observe-visibility="{ callback: animation, once: false}"
          >
            <Icon
              name="logo"
              viewBox="0 0 366 52"
            />
          </router-link>
          <div
            class="menu animation"
            v-observe-visibility="{ callback: animation, once: false}"
          >
            <div
              class="menu-link"
              v-for="link in menuLinks"
              :key="link.href"
            >
              <router-link
                :to="link.href"
                v-if="!link.popup"
              >
                {{ link.title }}
              </router-link>
              <button
                v-if="link.popup"
                type="button"
                @click.prevent="openPopup(link.popup)"
              >{{link.title }}</button>
            </div>
          </div>
          <!-- <div
            class="copyright animation"
            v-if="breakpoint === 'md' || breakpoint === 'sm'"
            v-observe-visibility="{ callback: animation, once: false}"
          >{{ navigationData?.CopyrightShort }}</div> -->
          <!-- <div
            class="top-right-wrapper animation"
            v-observe-visibility="{ callback: animation, once: false}"
          >
            <a
              :href="contacts?.[1]?.href"
              target="_blank"
              class="email email-mobile"
            >
              {{ contacts?.[1]?.name }}
            </a>
            <Socials class="socials" />
          </div> -->
        </div>

        <!-- Bottom -->
        <div
          class="bottom animation"
          v-observe-visibility="{ callback: animation, once: false}"
        >
          <div
            class="copyright-text"
            v-html="navigationData?.CopyrightLong"
          />
          <a
            :href="contacts?.[1]?.href"
            target="_blank"
            class="email email-desktop"
          >{{ contacts?.[1]?.name }}</a>
          <p
            class="copyright animation"
            v-observe-visibility="{ callback: animation, once: false}"
          >{{ navigationData?.CopyrightShort }}</p>
          <div class="made-in">{{ navigationData?.MadeInText }} <a
              href="https://www.bsagency.design/"
              target="_blank"
            >BS Agency</a></div>
        </div>
      </div>
    </div>
    <teleport to="#popups">
      <transition name="fade-slide-up">
        <Popup
          v-if="popupActive"
          @modal-closed="popupActive = false"
          :isShown="popupActive"
          :isNoHorPad="currentPopup === 'CertificatesPopup'"
          :noScroll="currentPopup === 'CertificatesPopup'"
        >
          <template #content>
            <component :is="currentPopup" />
          </template>
        </Popup>
      </transition>
    </teleport>
  </footer>
</template>

<script>
import Icon from './Icon.vue';
// import Socials from './Socials.vue';
import Popup from './Popup.vue';
import Alliance from './Alliance.vue';
import { animationMixin } from '@/components/mixins/animation';
import CertificatesPopup from './CertificatesPopup.vue';

export default {
  components: {
    // Socials,
    Icon,
    Popup,
    Alliance,
    CertificatesPopup,
  },
  name: 'PageFooter',
  mixins: [animationMixin],
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
    menuLinks() {
      return this.$store.getters.getNavigation;
    },
    navigationData() {
      return this.$store.getters.getNavigationData;
    },
    contacts() {
      return this.$store.getters.getContacts;
    },
  },
  data() {
    return {
      popupActive: false,
      currentPopup: 'Alliance',
    };
  },
  methods: {
    openPopup(popup) {
      this.popupActive = true;
      this.currentPopup = popup;
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  position: relative;
  color: color(basic);
  letter-spacing: -0.02em;
  background-color: color(bg);

  @include wRule(
    padding,
    (
      xl: 65 0,
      lg: 65 0,
      md: 56 0,
      sm: 40 0,
    )
  );

  &.has-top-margin {
    @include wRule(
      padding-top,
      (
        xl: 170,
        lg: 180,
        md: 130,
        sm: 180,
      )
    );
  }

  &.pt0 {
    padding-top: 0;
  }

  .wrapper {
    @include wRule(
      padding-top,
      (
        xl: 30,
        lg: 20,
        md: 24,
        sm: 42,
      )
    );

    border-top: 2px solid color(basic);
  }

  /*
  Top
   */
  .top {
    position: relative;
    display: flex;
    align-items: center;

    @include rule(
      justify-content,
      (
        xl: space-between,
        lg: flex-start,
      )
    );

    @include rule(
      flex-wrap,
      (
        lg: wrap,
      )
    );
  }

  .top-right-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include rule(position, (xl: relative, lg: absolute, md: relative));
    @include rule(top, (lg: 100%, md: auto));
    @include rule(right, (lg: 0%, md: auto));

    @include wRule(width, (
      lg: 100%,
      md: auto,
    ));

    @include rule(
      width,
      (
        sm: 100%,
      )
    );

    @include wRule(
      margin-left,
      (
        xl: 60,
        lg: 50,
        md: auto,
        sm: 0,
      )
    );
    @include wRule(
      margin-top,
      (
        lg: 30,
        md: 0,
        sm: 75,
      )
    );

    &.animation {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.5s ease 0.9s, transform 0.5s ease 0.9s;
    }
  }

  .logo {
    display: block;
    transition: opacity $ease-main;

    @include wRule(
      margin-right,
      (
        xl: 60,
        lg: 62,
        md: auto,
        sm: 0,
      )
    );

    @include wRule(margin-bottom, (sm: 80));

    &:hover {
      opacity: 0.8;
    }

    svg {
      display: block;

      @include wRule(
        width,
        (
          xl: 337,
          lg: 240,
          md: 282,
          sm: 358,
        )
      );
    }

    &.animation {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.5s ease 0.6s, transform 0.5s ease 0.6s;
    }
  }

  .menu {
    @include rule(
      display,
      (
        xl: flex,
        sm: grid,
        // md: none,
      )
    );

    @include rule(flex-grow, (xl: 1, lg: 0));

    @include wRule(margin-top, (md: 35, sm: 0));

    @include rule(order, (md: 1, sm: 0));

    @include wRule(column-gap, (sm: 40));
    @include wRule(row-gap, (sm: 48));

    align-items: center;
    // margin-right: auto;

    @include screen('sm', max) {
      grid-template-columns: repeat(2, 1fr);
    }

    &.animation {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.5s ease 0.7s, transform 0.5s ease 0.7s;
    }
  }

  .menu-link {
    @include wRule(
      font-size,
      (
        xl: 16,
        lg: 14,
        md: 12,
        sm: 25,
      )
    );

    font-weight: 700;
    line-height: 1;

    @include rule(line-height, (xl: 1, sm: 1.28));

    a,
    button {
      position: relative;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      letter-spacing: inherit;
      color: inherit;

      &::before {
        @include pseudo;

        font-size: inherit;
        left: 0;
        bottom: -0.04em;
        width: 100%;
        height: 0.1em;
        background-color: currentColor;
        transition: transform 0.4s ease;
        transform: scaleX(0);
        transform-origin: 0 50%;
      }

      @media (hover: hover) {
        &:hover {
          &::before {
            transform: scaleX(1);
          }
        }
      }
    }

    &:not(:first-child) {
      @include wRule(
        margin-left,
        (
          xl: 48,
          lg: 30, //40
          md: 36,
          sm: 0,
        )
      );
    }
  }

  .copyright {
    @include wRule(
      font-size,
      (
        xl: 16,
        lg: 14,
        md: 12,
        sm: 25,
      )
    );
    @include wRule(
      margin-right,
      (
        xl: 53,
        lg: 0,
      )
    );

    @include wRule(margin-bottom, (sm: 40));
    @include rule(order, (lg: 1, sm: -1));
    @include rule(width, (lg: 50%, sm: auto));

    line-height: 1.28;

    &.animation {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.5s ease 0.8s, transform 0.5s ease 0.8s;
    }
  }

  .email-mobile {
    @include rule(
      display,
      (
        md: none,
      ),
      min
    );
  }

  .email-desktop {
    // @include rule(
    //   display,
    //   (
    //     md: none,
    //   )
    // );

    @include wRule(
      margin-right,
      (
        xl: 55,
        lg: auto,
        md: 0,
      )
    );
  }

  .email {
    position: relative;

    @include wRule(
      font-size,
      (
        xl: 16,
        lg: 14,
        md: 12,
        sm: 25,
      )
    );

    line-height: 1.28;
    color: color(gray);

    &::before {
      @include pseudo;

      font-size: inherit;
      left: 0;
      bottom: -0.04em;
      width: 100%;
      height: 0.1em;
      background-color: currentColor;
      transition: transform 0.4s ease;
      transform: scaleX(0);
      transform-origin: 0 50%;
    }

    @media (hover: hover) {
      &:hover {
        &::before {
          transform: scaleX(1);
        }
      }
    }
  }

  .socials {
    @include wRule(
      margin-left,
      (
        lg: auto,
        md: 41,
        sm: 0,
      )
    );

    ::v-deep(.social) {
      @include wRule(
        width,
        (
          xl: 35,
          lg: 29,
          md: 28,
          sm: 52,
        )
      );
      @include wRule(
        height,
        (
          xl: 35,
          lg: 29,
          md: 28,
          sm: 52,
        )
      );

      &:not(:first-child) {
        @include wRule(
          margin-left,
          (
            xl: 34,
            lg: 26,
            md: 25,
            sm: 66,
          )
        );
      }
    }
  }

  /*
  Bottom
   */
  .bottom {
    justify-content: space-between;
    line-height: 1.28;
    color: color(gray);
    display: flex;

    @include rule(flex-direction, (sm: column));
    @include rule(flex-wrap, (lg: wrap));

    // @include rule(
    //   display,
    //   (
    //     sm: flex,
    //   ),
    //   min
    // );
    @include rule(justify-content, (xl: space-between, lg: flex-start));
    @include rule(
      align-items,
      (
        xl: flex-start,
        lg: flex-end,
        sm: flex-start,
      )
    );
    @include wRule(
      font-size,
      (
        xl: 16,
        lg: 14,
        md: 12,
        sm: 25,
      )
    );
    @include wRule(
      margin-top,
      (
        xl: 43,
        lg: 38,
        md: 38,
        sm: 77,
      )
    );

    &.animation {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.5s ease 1s, transform 0.5s ease 1s;
    }
  }

  .copyright-text {
    ::v-deep(br) {
      @include screen(sm, min) {
        display: none;
      }
    }

    @include wRule(
      margin-right,
      (
        xl: 55,
        lg: 55,
      )
    );
  }

  .bottom .copyright-text {
    @include wRule(
      margin-right,
      (
        md: 30,
        sm: 0,
      )
    );
    @include wRule(
      margin-bottom,
      (
        sm: 40,
      )
    );
  }

  .made-in {
    font-weight: 700;
    white-space: nowrap;

    @include rule(margin-left, (xl: auto, lg: 0));
    @include rule(text-align, (lg: right, sm: left));
    @include wRule(margin-top, (lg: 42, md: 38, sm: 86));
    @include rule(order, (lg: 2, sm: 1));
    @include rule(width, (lg: 50%, sm: auto));

    a {
      color: color(basic);

      &:hover {
        color: color(main);
      }
    }
  }
}

</style>
