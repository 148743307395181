import axios from 'axios';
import store from '@/store/index';

export default function api(path, locale, sort) {
  // eslint-disable-next-line no-nested-ternary
  return axios.get(`${process.env.VUE_APP_BACKEND_HOST}/${path}${locale ? `?_locale=${store.state.lang}` : ''}${sort ? (locale ? `&_sort=${sort}` : `?_sort=${sort}`) : ''}`).then((response) => {
    console.log(response.data);
    return response.data;
  });
}
