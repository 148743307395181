<template>
  <div
    class="slider-dots animation"
    v-observe-visibility="{ callback: animation, once: true}"
  >
    <div
      class="dot"
      v-for="(dot, dotKey) in dots"
      :key="dotKey"
      :class="{'is-active': (currentActive === dotKey)}"
      @click.prevent="swiper?.slideTo(dotKey)"
    ></div>
  </div>
</template>

<script>
import { animationMixin } from '@/components/mixins/animation';

export default {
  name: 'SliderDots',
  mixins: [animationMixin],
  props: {
    dots: {
      type: Array,
    },
    swiper: {
      type: Object,
    },
  },
  computed: {
    currentActive() {
      return this.swiper?.activeIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
.slider-dots {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;

  &:not(:last-child) {
    @include wRule(
      margin-right,
      (
        xl: 20,
        lg: 20,
        md: 20,
        sm: 20,
      )
    );
  }

  &.animation {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  &.is-animated {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 0.5s ease 0.6s, transform 0.5s ease 0.6s;
  }

  .dot {
    @include wRule(
      width,
      (
        xl: 6,
        lg: 5,
        md: 5,
        sm: 10,
      )
    );
    @include wRule(
      height,
      (
        xl: 6,
        lg: 5,
        md: 5,
        sm: 10,
      )
    );

    display: block;
    border-radius: 50%;
    background-color: color(basic-light);
    transition: color $ease-main, background $ease-main;
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        color: color(main);
        background-color: color(main);
      }
    }

    &.is-active {
      color: color(basic);
      background-color: color(basic);
    }

    &:not(:last-child) {
      @include wRule(
        margin-right,
        (
          xl: 18,
          lg: 12,
          md: 12,
          sm: 30,
        )
      );
    }
  }
}
</style>
