<template>
  <router-link
    :to="linkData.href"
    active-class="is-active"
    class="navigation-link"
    @click="navigationTransit"
  >{{ linkData.title }}
  </router-link>
</template>

<script>
export default {
  name: 'NavigationLink',
  emits: ['hideNav'],
  props: {
    linkData: {
      required: true,
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
  methods: {
    navigationTransit() {
      this.$emit('hideNav');
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-link {
  color: currentColor;
  font: inherit;
  letter-spacing: inherit;

  .site-navigation & {
    position: relative;
    display: inline-block;

    &::before,
    &::after {
      position: absolute;
      content: "";
      display: block;
      top: calc(50% - 1.5px);
      width: 50vw;
      background-color: currentColor;
      transform: scaleX(0);
      transition: transform $ease-main;

      @include wRule(
        height,
        (
          xl: 3,
          lg: 3,
          md: 3,
          sm: 3,
        )
      );

      @include screen("sm", max) {
        width: 80vw;
      }
    }

    &::before {
      right: 100%;
      transform-origin: 0% 50%;

      @include wRule(
        margin-right,
        (
          xl: 54,
          lg: 50,
          md: 50,
          sm: 49,
        )
      );
    }

    &::after {
      left: 100%;
      transform-origin: 100% 50%;

      @include wRule(
        margin-left,
        (
          xl: 54,
          lg: 50,
          md: 50,
          sm: 49,
        )
      );
    }

    &.is-active,
    &:hover {
      &::before,
      &::after {
        transform: scaleX(1);
      }
    }
  }
}
</style>
