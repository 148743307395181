<template>
  <div class="certificates-popup">
    <certificates-gallery
      :certificates="sectionCertificates?.Certificates"
      :initialSlide="clickedSlide"
    />
  </div>
</template>

<script>
import CertificatesGallery from './CertificatesSlider/components/CertificatesGallery.vue';
import api from '@/tools/api';

export default {
  components: { CertificatesGallery },
  name: 'CertificatesPopup',
  data() {
    return {
      pageContent: {},
    };
  },
  methods: {
    async getPageContent() {
      this.pageContent = await api('page-about-content', true);
    },
  },
  computed: {
    sectionCertificates() {
      return this.pageContent?.SectionCertificates;
    },
  },
  watch: {
    currentLang() {
      this.getPageContent();
    },
  },
  beforeMount() {
    this.getPageContent();
  },
};
</script>

<style lang="scss" scoped>
.certificates-popup {
  display: flex;
  width: 100%;
}
</style>
