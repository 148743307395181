<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <swiper
      key="home-slider"
      v-if="breakpoint==='lg' || breakpoint==='xl'"
      class="home-page"
      :speed="500"
      :autoHeight="true"
      :slidesPerView="'auto'"
      :followFinger="false"
      :direction="'veritcal'"
      :keyboard="{enabled: true}"
      :mousewheel="true"
      :observer="true"
      :observeSlideChildren="true"
      :resizeObserver="true"
      :simulateTouch="false"
      :pagination="{
      renderBullet: generateBullet,
      bulletClass: 'slider-home-bullet',
      bulletActiveClass: 'is-active',
      el: '.slider-home-bullets'}"
      @swiper="pageSwiperInstance = $event"
      @slideNextTransitionStart="slideMoveDown"
      @slidePrevTransitionStart="slideMoveUp"
      @activeIndexChange="changeBulletsColor"
      @reachEnd="sliderReachEnd"
      @fromEdge="sliderGoAwayFromEnd"
    >
      <template #container-start>
        <div
          :class="['slider-home-bullets', 'animation', {'is-dark': pageSwiperInstance.activeIndex === 2}]"
          v-observe-visibility="animation"
        ></div>
      </template>

      <!-- Main -->
      <swiper-slide v-slot="{isActive, isPrev}">
        <page-section
          class="home-lead fullpage"
          :class="{'is-leave-animation': isPrev && isDown, 'is-enter-animation': (isActive && !isDown)}"
        >
          <template #background>
            <bg-image>
              <picture v-if="leadContent?.Background?.mime.includes('image')">
                <source
                  v-if="leadContent?.BackgroundMobile"
                  :srcset="getUrl(leadContent?.BackgroundMobile?.url)"
                  :type="leadContent?.BackgroundMobile?.mime"
                  media="(max-width: 767px)"
                />
                <source
                  v-if="leadContent?.Background"
                  :srcset="getUrl(leadContent?.Background?.url)"
                  :type="leadContent?.Background?.mime"
                />
                <img
                  :src="getUrl(leadContent?.Background?.url)"
                  alt="@@"
                >
              </picture>
              <video
                v-if="leadContent?.Background?.mime?.includes('video')"
                :src="getUrl(leadContent?.Background.url)"
                loop
                autoplay
                muted
                playsinline
              >
                <source
                  v-if="breakpoint==='sm'"
                  media="(min-width: 767px)"
                  :srcset="getUrl(leadContent?.BackgroundMobile?.url)"
                >
              </video>
            </bg-image>
          </template>
          <template #content>
            <div class="container">
              <div class="description">
                <h1
                  class="animation"
                  v-observe-visibility="animation"
                >{{leadContent?.Title}}</h1>
                <p
                  class="animation"
                  v-observe-visibility="animation"
                >{{leadContent?.Subtitle}}</p>
              </div>
              <div class="link">
                <block-link
                  :href="'/about'"
                  :isRoute="true"
                >
                  <span>{{leadContent?.ButtonText}}</span>
                  <icon name="arrow" />
                </block-link>
              </div>
              <div class="data">
                <ul>
                  <li
                    v-for="(contact, index) in contacts"
                    :key=index
                    v-observe-visibility="animation"
                    class="animation"
                  >
                    <contact-link v-bind="contact" />
                  </li>
                </ul>
                <VButton
                  class="thin animation"
                  :tag="'a'"
                  :href="getUrl(leadContent?.PresentationFile?.url)"
                  target="_blank"
                  v-observe-visibility="animation"
                  v-if="false"
                >
                  <template #start>
                    <Icon name="pdf" />
                  </template>
                  <template #default>{{leadContent?.DownloadPresentationButtonText}}

                  </template>
                </VButton>
              </div>
            </div>
          </template>
        </page-section>
      </swiper-slide>

      <!-- Services -->
      <swiper-slide v-slot="{isActive, isPrev, isNext}">
        <page-section
          class="home-service fullpage"
          :class="{'is-leave-animation': isPrev && isDown, 'is-active-animation': isActive && isDown, 'is-active-reverse-animation':  isNext && !isDown, 'is-enter-animation': (isActive && !isDown)}"
        >
          <template #background>
            <bg-image>
              <picture v-if="servicesContent?.Background?.mime.includes('image')">
                <source
                  v-if="servicesContent?.BackgroundMobile"
                  :srcset="getUrl(servicesContent?.BackgroundMobile?.url)"
                  :type="servicesContent?.BackgroundMobile?.mime"
                  media="(max-width: 767px)"
                />
                <source
                  v-if="servicesContent?.Background"
                  :srcset="getUrl(servicesContent?.Background?.url)"
                  :type="servicesContent?.Background?.mime"
                />
                <img
                  :src="getUrl(servicesContent?.Background?.url)"
                  alt="@@"
                >
              </picture>
              <video
                v-if="servicesContent?.Background?.mime?.includes('video')"
                :src="getUrl(servicesContent?.Background.url)"
                loop
                autoplay
                muted
                playsinline
              >
                <source
                  v-if="breakpoint==='sm'"
                  media="(min-width: 767px)"
                  :srcset="getUrl(servicesContent?.BackgroundMobile?.url)"
                >
              </video>
            </bg-image>
          </template>
          <template #header>
            <div class="container">
              <div class="page-section__header">
                <h2
                  class="animation"
                  v-observe-visibility="animation"
                >{{ servicesContent?.Title }}</h2>
              </div>
            </div>
          </template>
          <template #content>
            <div class="container">
              <div class="features">
                <feature
                  v-for="(feature, index) in servicesContent?.Services"
                  :key="index"
                  :iconUrl="feature.Icon.url"
                  :text="feature.ShortName"
                />
              </div>
            </div>
            <blockLink
              classList="block-link_large"
              :href="'/services'"
              :isRoute="true"
            >
              <div class="block-link__wrapper">
                <span>{{ servicesContent?.LinkText }}</span>
                <icon name="arrow" />
              </div>
            </blockLink>
          </template>
        </page-section>
      </swiper-slide>

      <!-- Base -->
      <swiper-slide v-slot="{isActive,  isPrev, isNext}">
        <page-section
          class="home-base fullpage"
          :class="{'is-leave-animation': isPrev && isDown, 'is-active-animation': isActive && isDown, 'is-active-reverse-animation':  isNext && !isDown, 'is-enter-animation': (isActive && !isDown)}"
        >
          <template #background>
            <bg-image>
              <picture v-if="baseContent?.Background?.mime.includes('image')">
                <source
                  v-if="baseContent?.BackgroundMobile"
                  :srcset="getUrl(baseContent?.BackgroundMobile?.url)"
                  :type="baseContent?.BackgroundMobile?.mime"
                  media="(max-width: 767px)"
                />
                <source
                  v-if="baseContent?.Background"
                  :srcset="getUrl(baseContent?.Background?.url)"
                  :type="baseContent?.Background?.mime"
                />
                <img
                  :src="getUrl(baseContent?.Background?.url)"
                  alt="@@"
                >
              </picture>
              <video
                v-if="baseContent?.Background?.mime?.includes('video')"
                :src="getUrl(baseContent?.Background.url)"
                loop
                autoplay
                muted
                playsinline
              >
                <source
                  v-if="breakpoint==='sm'"
                  media="(min-width: 767px)"
                  :srcset="getUrl(baseContent?.BackgroundMobile?.url)"
                >
              </video>
            </bg-image>
          </template>
          <template #content>
            <div class="container">
              <div class="column">
                <h2
                  class="animation"
                  v-observe-visibility="animation"
                >{{ baseContent?.Title }}</h2>
                <p
                  class="animation"
                  v-observe-visibility="animation"
                >{{ baseContent?.Text }}</p>
              </div>
            </div>
            <blockLink
              classList="block-link_large"
              :href="'/base'"
              :isRoute="true"
            >
              <div class="block-link__wrapper">
                <span>{{ baseContent?.LinkText }}</span>
                <icon name="arrow" />
              </div>
            </blockLink>
          </template>
        </page-section>
      </swiper-slide>

      <!-- Instruments -->

      <swiper-slide v-slot="{isActive,  isPrev, isNext}">
        <InstrumentsSlider :data="instrumentsContent"
        class="home-instruments fullpage"
        :class="{'is-leave-animation': isPrev && isDown, 'is-active-animation': isActive && isDown, 'is-active-reverse-animation':  isNext && !isDown, 'is-enter-animation': (isActive && !isDown)}" />
      </swiper-slide>

      <!-- Alliance -->
      <!-- <swiper-slide v-slot="{isActive,  isPrev, isNext}">
        <page-section
          class="home-alliance fullpage"
          :class="{'is-leave-animation': isPrev && isDown, 'is-active-animation': isActive && isDown, 'is-active-reverse-animation':  isNext && !isDown, 'is-enter-animation': (isActive && !isDown)}"
        >
          <template #header>
            <div class="container">
              <div class="page-section__header">
                <h2
                  class="animation"
                  v-observe-visibility="animation"
                >{{ allianceContent?.Title }}</h2>
                <p
                  class="animation"
                  v-observe-visibility="animation"
                >{{ allianceContent?.Description }}</p>
              </div>
            </div>
          </template>
          <template #content>
            <div class="container">
              <alliance-grid></alliance-grid>
            </div>
          </template>
        </page-section>
      </swiper-slide> -->

      <!-- Footer --->
      <swiper-slide v-slot="{isActive, isNext, isPrev}">
        <page-footer :class="{'is-leave-animation': isPrev, 'is-active-animation': isActive, 'is-enter-animation': isNext}"></page-footer>
      </swiper-slide>
    </swiper>

    <section
      key="home-page"
      v-else
      class="home-page"
    >
      <slider-home-bullets
        :bullets="sliderBulletsName"
        :current-section="currentSection"
        :swiper="pageSwiperInstance"
      />
      <!-- Main -->
      <div
        class="swiper-slide"
        id="slide-section-0"
        v-observe-visibility="{callback: (isVisible, entry) => observeSection(isVisible, entry, 'slide-section-0'), intersection: {threshold: 0.6}}"
      >
        <page-section class="home-lead fullpage">
          <template #background>
            <bg-image>
              <picture v-if="leadContent?.Background?.mime.includes('image')">
                <source
                  v-if="leadContent?.BackgroundMobile"
                  :srcset="getUrl(leadContent?.BackgroundMobile?.url)"
                  :type="leadContent?.BackgroundMobile?.mime"
                  media="(max-width: 767px)"
                />
                <source
                  v-if="leadContent?.Background"
                  :srcset="getUrl(leadContent?.Background?.url)"
                  :type="leadContent?.Background?.mime"
                />
                <img
                  :src="getUrl(leadContent?.Background?.url)"
                  alt="@@"
                >
              </picture>
              <video
                v-if="leadContent?.Background?.mime?.includes('video')"
                :src="getUrl(leadContent?.Background.url)"
                loop
                autoplay
                muted
                playsinline
              >
                <source
                  v-if="breakpoint==='sm'"
                  media="(min-width: 767px)"
                  :srcset="getUrl(leadContent?.BackgroundMobile?.url)"
                >
              </video>
            </bg-image>
          </template>
          <template #content>
            <div class="container">
              <div class="description">
                <h1
                  class="animation"
                  v-observe-visibility="animation"
                >{{leadContent?.Title}}</h1>
                <p
                  class="animation"
                  v-observe-visibility="animation"
                >{{leadContent?.Subtitle}}</p>
              </div>
              <div class="link">
                <block-link
                  :href="'/about'"
                  :isRoute="true"
                >
                  <span>{{leadContent?.ButtonText}}</span>
                  <icon name="arrow" />
                </block-link>
              </div>
              <div class="data">
                <ul>
                  <li
                    v-for="(contact, index) in contacts"
                    :key=index
                    v-observe-visibility="animation"
                    class="animation"
                  >
                    <contact-link v-bind="contact" />
                  </li>
                </ul>
                <VButton
                  class="thin animation"
                  :tag="'a'"
                  :href="getUrl(leadContent?.PresentationFile?.url)"
                  target="_blank"
                  v-observe-visibility="animation"
                  v-if="false"
                >
                  <template #start>
                    <Icon name="pdf" />
                  </template>
                  <template #default>{{leadContent?.DownloadPresentationButtonText}}
                  </template>
                </VButton>
              </div>
            </div>
          </template>
        </page-section>
      </div>

      <!-- Services -->
      <div
        class="swiper-slide"
        id="slide-section-1"
        v-observe-visibility="{callback: (isVisible, entry) => observeSection(isVisible, entry, 'slide-section-1'), intersection: {threshold: 0.6}}"
      >
        <page-section class="home-service fullpage">
          <template #background>
            <bg-image>
              <picture v-if="servicesContent?.Background?.mime.includes('image')">
                <source
                  v-if="servicesContent?.BackgroundMobile"
                  :srcset="getUrl(servicesContent?.BackgroundMobile?.url)"
                  :type="servicesContent?.BackgroundMobile?.mime"
                  media="(max-width: 767px)"
                />
                <source
                  v-if="servicesContent?.Background"
                  :srcset="getUrl(servicesContent?.Background?.url)"
                  :type="servicesContent?.Background?.mime"
                />
                <img
                  :src="getUrl(servicesContent?.Background?.url)"
                  alt="@@"
                >
              </picture>
              <video
                v-if="servicesContent?.Background?.mime?.includes('video')"
                :src="getUrl(servicesContent?.Background.url)"
                loop
                autoplay
                muted
                playsinline
              >
                <source
                  v-if="breakpoint==='sm'"
                  media="(min-width: 767px)"
                  :srcset="getUrl(servicesContent?.BackgroundMobile?.url)"
                >
              </video>
            </bg-image>
          </template>
          <template #header>
            <div class="container">
              <div class="page-section__header">
                <h2
                  class="animation"
                  v-observe-visibility="animation"
                >{{ servicesContent?.Title }}</h2>
              </div>
            </div>
          </template>
          <template #content>
            <div class="container">
              <div class="features">
                <feature
                  v-for="(feature, index) in servicesContent?.Services"
                  :key="index"
                  :iconUrl="feature.Icon.url"
                  :text="feature.ShortName"
                />
              </div>
            </div>
            <blockLink
              classList="block-link_large"
              :href="'/services'"
              :isRoute="true"
            >
              <div class="block-link__wrapper">
                <span>{{ servicesContent?.LinkText }}</span>
                <icon name="arrow" />
              </div>
            </blockLink>
          </template>
        </page-section>
      </div>

      <!-- Base -->
      <div
        class="swiper-slide"
        id="slide-section-2"
        v-observe-visibility="{callback: (isVisible, entry) => observeSection(isVisible, entry, 'slide-section-2'), intersection: {threshold: 0.6}}"
      >
        <page-section class="home-base fullpage">
          <template #background>
            <bg-image>
              <picture v-if="baseContent?.Background?.mime.includes('image')">
                <source
                  v-if="baseContent?.BackgroundMobile"
                  :srcset="getUrl(baseContent?.BackgroundMobile?.url)"
                  :type="baseContent?.BackgroundMobile?.mime"
                  media="(max-width: 767px)"
                />
                <source
                  v-if="baseContent?.Background"
                  :srcset="getUrl(baseContent?.Background?.url)"
                  :type="baseContent?.Background?.mime"
                />
                <img
                  :src="getUrl(baseContent?.Background?.url)"
                  alt="@@"
                >
              </picture>
              <video
                v-if="baseContent?.Background?.mime?.includes('video')"
                :src="getUrl(baseContent?.Background.url)"
                loop
                autoplay
                muted
                playsinline
              >
                <source
                  v-if="breakpoint==='sm'"
                  media="(min-width: 767px)"
                  :srcset="getUrl(baseContent?.BackgroundMobile?.url)"
                >
              </video>
            </bg-image>
          </template>
          <template #content>
            <div class="container">
              <div class="column">
                <h2
                  class="animation"
                  v-observe-visibility="animation"
                >{{ baseContent?.Title }}</h2>
                <p
                  class="animation"
                  v-observe-visibility="animation"
                >{{ baseContent?.Text }}</p>
              </div>
            </div>
            <blockLink
              classList="block-link_large"
              :href="'/base'"
              :isRoute="true"
            >
              <div class="block-link__wrapper">
                <span>{{ baseContent?.LinkText }}</span>
                <icon name="arrow" />
              </div>
            </blockLink>
          </template>
        </page-section>
      </div>

      <!-- Instruments -->

      <div
        class="swiper-slide"
        id="slide-section-3"
        v-observe-visibility="{callback: (isVisible, entry) => observeSection(isVisible, entry, 'slide-section-3'), intersection: {threshold: 0.6}}"
      >
        <InstrumentsSlider :data="instrumentsContent" />
      </div>

      <!-- Alliance -->
      <!-- <div
        class="swiper-slide"
        id="slide-section-3"
        v-observe-visibility="{callback: (isVisible, entry) => observeSection(isVisible, entry, 'slide-section-3'), intersection: {threshold: 0.6}}"
      >
        <page-section class="home-alliance fullpage">
          <template #header>
            <div class="container">
              <div class="page-section__header">
                <h2
                  class="animation"
                  v-observe-visibility="animation"
                >{{ allianceContent?.Title }}</h2>
                <p
                  class="animation"
                  v-observe-visibility="animation"
                >{{ allianceContent?.Description }}</p>
              </div>
            </div>
          </template>
          <template #content>
            <div class="container">
              <alliance-grid></alliance-grid>
            </div>
          </template>
        </page-section>
      </div> -->
    </section>
  </transition>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import SwiperCore, { Keyboard, Mousewheel, Pagination } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/vue';
import BgImage from './BgImage.vue';
import PageSection from './PageSection.vue';
// import AllianceGrid from './AllianceGrid.vue';
import Icon from './Icon.vue';
import BlockLink from './BlockLink.vue';
import ContactLink from './ContactLink.vue';
import Feature from './Feature.vue';
import { animationMixin } from '@/components/mixins/animation';
import SliderHomeBullets from '@/components/SliderHomeBullets.vue';
import 'swiper/swiper.scss';
import api from '@/tools/api';
import getUrl from '@/tools/getUrl';
import VButton from './VButton.vue';
import PageFooter from './PageFooter.vue';
import InstrumentsSlider from '@/views/Base/components/InstrumentsSlider.vue';
import VueScrollTo from 'vue-scrollto';

SwiperCore.use([Keyboard, Mousewheel, Pagination]);

export default {
  name: 'HomePage',
  emits: [
    'pageSliderScrollDown',
    'pageSliderScrollUp',
    'pageSliderReachFooter',
    'pageSliderGoAwayFooter',
  ],
  mixins: [animationMixin],
  components: {
    Swiper,
    SwiperSlide,
    PageSection,
    BgImage,
    // AllianceGrid,
    Icon,
    BlockLink,
    ContactLink,
    Feature,
    SliderHomeBullets,
    VButton,
    PageFooter,
    InstrumentsSlider,
  },
  data() {
    return {
      pageContent: {},
      instrumentsData: {},
      planes: {},
      pageSwiperInstance: {},
      isDown: true,
      currentSection: 0,
      isTools: this.$route.hash === 'tools',
    };
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
    contacts() {
      return this.$store.getters.getContacts;
    },
    sliderBulletsName() {
      return [this.leadContent, this.servicesContent, /* this.airplaneContent, */ this.baseContent, /* this.allianceContent, */ this.instrumentsData, '-'].map(
        (section) => {
          let title = '';
          if (section) {
            title = section.ScreenName ? section.ScreenName : section.InstrumentsSectionTitle;
          }
          return title;
        },
      );
    },
    leadContent() {
      return this.pageContent?.ScreenOne;
    },
    servicesContent() {
      return this.pageContent?.ScreenServices;
    },
    allianceContent() {
      return this.pageContent?.ScreenAlliance;
    },
    baseContent() {
      return this.pageContent?.ScreenBase;
    },
    airplaneContent() {
      return this.pageContent?.ScreenPlanes;
    },
    instrumentsContent() {
      return {
        title: this.instrumentsData?.InstrumentsSectionTitle,
        slides: this.instrumentsData?.Instruments,
      };
    },
    currentLang() {
      return this.$store.getters.getLang;
    },
    startNumber() {
      console.log('startNumber');
      console.log(this.isTools);
      return this.isTools ? 3 : 0;
    },
  },
  methods: {
    setLeadHeight() {
      const observeFor = document.querySelector('.home-lead');

      const resizer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          const el = entry.target;

          if (this.breakpoint === 'sm') {
            const wH = window.innerHeight;
            el.style.height = `${wH}px`;
          } else {
            el.style.height = '';
          }
        });
      });

      resizer.observe(observeFor);
    },
    generateBullet(i, className) {
      const name = this.sliderBulletsName[i];

      return `<span class="${className} ${i >= 4 ? 'hide' : ''}">${name}</span>`;
    },
    changeBulletsColor(sw) {
      console.log(sw.activeIndex);
      // const isDark = sw.activeIndex === 2;
      // if (isDark) {
      //   sw.pagination.el.classList.add('is-dark');
      // } else {
      //   sw.pagination.el.classList.remove('is-dark');
      // }
    },
    observeSection(isVisible, entry, section) {
      if (isVisible && entry.intersectionRatio > 0.6) {
        this.currentSection = section;
      }
    },
    slideMoveDown() {
      this.$emit('pageSliderScrollDown', this.pageSwiperInstance?.activeIndex);
      this.isDown = true;
    },
    slideMoveUp() {
      this.isDown = false;
      this.$emit('pageSliderScrollUp', this.pageSwiperInstance?.activeIndex);
    },
    sliderReachEnd() {
      this.$emit('pageSliderReachFooter');
      document.body.classList.add('is-footer-reached');
    },
    sliderGoAwayFromEnd() {
      this.$emit('pageSliderGoAwayFooter');
      document.body.classList.remove('is-footer-reached');
    },
    async getPageContent() {
      this.pageContent = await api('page-main-content', true);
    },
    async getPlanes() {
      this.planes = await api('planes', true);
    },
    async getInstruments() {
      this.instrumentsData = await api('page-base-content', true);
    },
    getUrl,
    scrollToId(id) {
      const target = document.getElementById(id);
      if (target) {
        VueScrollTo.scrollTo(target, 500, {
          offset: 0, easing: 'ease',
        });
      }
    },
    setPosition() {
      if (this.isTools) {
        if (this.breakpoint === 'lg' || this.breakpoint === 'xl') {
          this.pageSwiperInstance.slideTo(3);
        }
        this.scrollToId('slide-section-3');
      }
    },
  },
  watch: {
    sliderBulletsName() {
      if (this.pageSwiperInstance?.pagination) {
        this.pageSwiperInstance.pagination.render();
        this.pageSwiperInstance.pagination.update();
      }
    },
    currentLang() {
      this.getPageContent();
      this.getPlanes();
      this.getInstruments();
    },
    $route(route) {
      this.isTools = route.hash === 'tools';
      this.setPosition();
    },
  },
  async beforeMount() {
    await this.getPageContent();
    await this.getPlanes();
    await this.getInstruments();
    document.body.classList.remove('is-footer-reached');
  },
  mounted() {
    this.setLeadHeight();
    this.setPosition();
    // window.addEventListener('scroll', () => {
    //   this.$refs.closeTooltips.click();
    // });
  },
  unmounted() {
    document.body.classList.remove('is-footer-reached');
  },
};
</script>

<style lang="scss" scoped>
.home-page {
  width: 100%;
  height: 100vh;
  overflow: hidden;

  @include screen("md", max) {
    height: auto;
  }

  ::v-deep(> .swiper-wrapper) {
    flex-direction: column;
    transition-timing-function: cubic-bezier(0.42, -0.01, 0.84, 0.64);
  }
}

.home-lead {
  // @include wRule(
  //   padding,
  //   (
  //     xl: 200 0 40,
  //     lg: 150 0 50,
  //     md: 160 0 50,
  //     sm: 128 0 92,
  //   )
  // );

  @include wRule(
    padding,
    (
      xl: 200 0 100,
      lg: 150 0 90,
      md: 160 0 90,
      sm: 128 0 92,
    )
  );

  @include screen("md", max) {
    height: 100vh;
  }

  ::v-deep(.bg-image) {
    @include screen("md", max) {
      position: fixed;
      z-index: 0;
    }
  }

  ::v-deep(p) {
    @include rule(
      line-height,
      (
        xl: 55 / 48,
        lg: 39 / 30,
        md: 37 / 30,
        sm: 1.1,
      )
    );

    @include wRule(
      font-size,
      (
        xl: 48,
        lg: 30,
        md: 30,
        sm: 38,
      )
    );
    @include rule(
      max-width,
      (
        xl: 87%,
        md: 76%,
        sm: none,
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 38,
          lg: 40,
          md: 35,
          sm: 45,
        )
      );
    }
  }

  ::v-deep(.page-section__content) {
    @include screen("sm", min) {
      display: flex;
      align-items: center;
    }
  }

  .container {
    display: grid;

    @include rule(
      grid-template-columns,
      (
        xl: 72% 1fr,
        lg: 65.5% 1fr,
        md: 1fr 36%,
        sm: 100%,
      )
    );

    @include rule(
      grid-template-rows,
      (
        xl: auto pxtovw(40, xl) auto,
        lg: auto pxtovw(40, lg) auto,
        md: repeat(2, auto),
        sm: 1fr repeat(2, auto),
      )
    );

    @include wRule(
      column-gap,
      (
        xl: 40,
        lg: 40,
        md: 40,
        sm: 0,
      )
    );

    @include screen("sm", max) {
      // flex-wrap: wrap;
      // align-content: space-between;
      height: 100%;
    }
  }

  ul {
    box-sizing: border-box;

    @include wRule(
      padding-right,
      (
        xl: 5,
        lg: 15,
        md: 0,
      )
    );
  }

  li {
    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 15,
          lg: 15,
          md: 8,
          sm: 16,
        )
      );
    }

    &.animation {
      opacity: 0;
      transform: translate3d(20px, 0, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.5s ease, transform 0.5s ease;

      @for $i from 1 to 7 {
        &:nth-child(#{$i}) {
          transition-delay: 0.8s + 0.1s * $i;
        }
      }
    }
  }

  .description {
    grid-column: 1 / 2;
    grid-row: 1 / 3;

    @include screen("md", max) {
      grid-column: 1 / 3;
      grid-row: 1 / 2;
    }

    @include screen("sm", max) {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
    }
  }

  .link {
    grid-column: 1 / 2;
    grid-row: 3 / 4;

    @include wRule(
      margin-top,
      (
        xl: 90,
        lg: 94,
        md: 86,
        sm: 132,
      )
    );

    @include screen("md", max) {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
    }

    @include screen("sm", max) {
      grid-column: 1 / 2;
      grid-row: 3 / 4;
    }
  }

  .data {
    grid-column: 2 / 3;
    grid-row: 2 / 4;

    @include screen("md", max) {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
    }

    @include screen("sm", max) {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }

    @include wRule(
      padding-top,
      (
        xl: 68,
        lg: 40,
        md: 31,
        sm: 0,
      )
    );
  }

  .v-button {
    width: 100%;

    @include screen("xxl", min) {
      max-width: 366px;
    }

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 60,
          lg: 38,
          md: 38,
          sm: 40,
        )
      );
    }

    &.animation {
      opacity: 0;
      transform: translate3d(20px, 0, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.5s ease, transform 0.5s ease;
      transition-delay: 1.5s;
    }
  }
}

.home-service {
  @include wRule(
    padding-top,
    (
      xl: 168,
      lg: 122,
      md: 125,
      sm: 118,
    )
  );

  @include screen("xxl", min) {
    padding-top: 260px;
  }

  .features {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, auto);
    flex-wrap: wrap;
    box-sizing: border-box;

    @include screen("sm", max) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, auto);
    }

    @include wRule(
      grid-row-gap,
      (
        xl: 166,
        lg: 50,
        md: 66,
        sm: 65,
      )
    );
    @include rule(
      grid-column-gap,
      (
        sm: pxtovw(73, sm),
      )
    );
    @include wRule(
      padding-left,
      (
        xl: 155,
        lg: 90,
        md: 0,
        sm: 40,
      )
    );
    @include wRule(
      margin-top,
      (
        xl: 104,
        lg: 72,
        md: 76,
        sm: 86,
      )
    );

    @include screen("xxl", min) {
      margin-top: 180px;
    }
  }

  ::v-deep(.feature) {
    width: 100%;
    box-sizing: border-box;

    @include wRule(
      padding-left,
      (
        xl: 42,
        lg: 42,
        md: 65,
        sm: 0,
      )
    );
    @include rule(
      max-width,
      (
        xl: pxtovw(223),
        lg: pxtovw(205, lg),
        md: none,
      )
    );

    &:nth-child(4n + 1) {
      padding-left: 0;

      @include rule(
        max-width,
        (
          xl: pxtovw(181),
          lg: pxtovw(163, lg),
          md: pxtovw(160, md),
          sm: none,
        )
      );
    }
  }
}

.home-airplanes {
  @include wRule(
    padding,
    (
      xl: 168 0 68,
      lg: 122 0 50,
      md: 174 0 100,
      sm: 105 0 76,
    )
  );

  @include screen("xxl", min) {
    padding-top: 260px;
  }

  ::v-deep(.page-section__content) {
    position: static;
  }

  ::v-deep(.page-section__header) {
    position: relative;
    z-index: 3;
  }

  .plane {
    @include wRule(
      margin-top,
      (
        xl: 86,
        lg: 68,
        md: 46,
        sm: 70,
      )
    );
  }
}

.home-alliance {
  @include wRule(
    padding,
    (
      xl: 168 0 68,
      lg: 122 0 50,
      md: 32 0 50,
      sm: 98 0 100,
    )
  );

  @include screen("xxl", min) {
    padding-top: 260px;
  }

  ::v-deep(.page-section__content) {
    @include screen("sm", min) {
      display: flex;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;
    }

    @include screen("md", min) {
      align-items: flex-start;
      align-content: flex-start;
    }
  }

  ::v-deep(.page-section__header) {
    h2 + p {
      @include rule(
        margin-top,
        (
          xl: 0,
          sm: pxtovw(34, sm),
        )
      );
    }

    p {
      @include rule(
        max-width,
        (
          xl: pxtovw(560),
          lg: pxtovw(380, lg),
          md: pxtovw(300, md),
          sm: none,
        )
      );
      @include wRule(
        margin-left,
        (
          xl: 40,
          lg: 40,
          md: 30,
          sm: 0,
        )
      );
    }
  }

  ::v-deep(.alliance-grid) {
    @include wRule(
      margin-top,
      (
        xl: 80,
        lg: 42,
        md: 62,
        sm: 90,
      )
    );
  }
}

.home-base {
  @include wRule(
    padding-top,
    (
      xl: 136,
      lg: 122,
      md: 128,
      sm: 342,
    )
  );

  @include screen("xxl", min) {
    padding-top: 260px;
  }

  ::v-deep(.page-section__content) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .column {
    box-sizing: border-box;
    width: 100%;

    @include rule(
      max-width,
      (
        xl: pxtovw(500),
        lg: pxtovw(500, lg),
        md: pxtovw(400, md),
        sm: none,
      )
    );

    @include wRule(
      padding-bottom,
      (
        md: 132,
        sm: 0,
      )
    );

    ::v-deep(p) {
      box-sizing: border-box;

      @include rule(
        min-height,
        (
          xl: 33.8vh,
          lg: 34.7vh,
          md: 0,
        )
      );

      @include wRule(
        padding-top,
        (
          xl: 22,
          lg: 0,
        )
      );

      @include wRule(
        padding-bottom,
        (
          xl: 50,
          lg: 50,
          md: 0,
        )
      );

      @include screen("xxl", min) {
        min-height: 365px;
      }
    }
  }
}

.home-instruments {

  ::v-deep(.swiper-wrapper) {
    align-items: stretch;
  }
}

.slider-home-bullets {
  position: fixed;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  z-index: 2;
  transform-origin: 0 0;
  bottom: 0;
  opacity: 0;
  color: color(basic-light);
  transform: rotate(-90deg) translate3d(-20px, 0, 0);
  transition: transform 0.3s ease, opacity 0.3s ease, color 0.3s ease;
  @include screen("sm", max) {
    display: none;
  }
  @include wRule(
    height,
    (
      xl: 25,
      lg: 25,
      md: 25,
      sm: 0,
    )
  );
  @include wRule(
    left,
    (
      xl: 100,
      lg: 68,
      md: 47,
      sm: 0,
    )
  );
  @include wRule(
    bottom,
    (
      xl: 80,
      lg: 98,
      md: 64,
      sm: 0,
    )
  );
  @include screen("sm", max) {
    display: none;
  }

  &.is-dark {
    color: color(basic);
  }

  &.is-animated {
    opacity: 1;
    transform: rotate(-90deg) translate3d(0, 0, 0);
    transition-delay: 0.5s;
  }

  .is-footer-reached & {
    @include screen("md", min) {
      visibility: hidden;
    }
  }
}
::v-deep(.slider-home-bullet) {
  display: inline-block;
  border-radius: 0;
  background-color: currentColor;
  width: auto;
  font-size: 0;
  transition: background-color 0.25s ease, font-size 0.5s ease;
  overflow: hidden;
  letter-spacing: normal;
  cursor: default;
  @include wRule(
    min-height,
    (
      xl: 2,
      lg: 2,
      md: 2,
      sm: 2,
    )
  );
  @include wRule(
    min-width,
    (
      xl: 14,
      lg: 19,
      md: 14,
      sm: 16,
    )
  );
  &:not(:last-child) {
    @include wRule(
      margin-left,
      (
        xl: 8,
        lg: 10,
        md: 8,
        sm: 8,
      )
    );
  }
  &.is-active {
    background-color: transparent;
    width: auto;
    cursor: default;
    @include wRule(
      font-size,
      (
        xl: 16,
        lg: 14,
        md: 12,
        sm: 24,
      )
    );
  }

  &.hide {
    display: none;
  }
}

</style>
