import { createRouter, createWebHistory } from 'vue-router';
import Index from '../views/Index.vue';
import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Index,
  },
  {
    path: '/tools',
    redirect: () => ({
      path: '/',
      hash: 'tools',
    }),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
  },
  {
    path: '/base',
    name: 'Base',
    component: () => import('../views/Base/Base.vue'),
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('../views/Services.vue'),
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import('../views/Contacts/Contacts.vue'),
    meta: {
      footerMargin: true,
    },
  },
  {
    path: '/privacy-policy',
    name: 'Policy',
    component: () => import('../views/PrivacyPolicy/PrivacyPolicy.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

router.beforeEach((to) => {
  if (to?.name) {
    document.title = `${store.getters.getNavigationData?.[`NamePage${to.name}`] || to.name} | СКАЙПРО Техникс`;
  }
});

export default router;
