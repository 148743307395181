<template>
  <div class="alliance">
    <alliance-grid></alliance-grid>
  </div>
</template>

<script>
import AllianceGrid from './AllianceGrid.vue';

export default {
  name: 'Alliance',
  components: {
    AllianceGrid,
  },
};
</script>

<style lang="scss" scoped>
.alliance {
  display: block;
  width: 100%;
}
</style>
