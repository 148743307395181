<template>
  <div class="certificates-gallery">
    <div
      class="main animation"
      v-observe-visibility="animation"
    >
      <swiper
        :speed="500"
        :effect="'fade'"
        :observe="true"
        :observeChildren="true"
        :fadeEffect="{crossFade: true}"
        :slides-per-view="1"
        :watchOverflow="true"
        :initialSlide="initialSlide"
        :pagination="{type: 'custom', renderCustom: renderCustom}"
        @active-index-change="switchDescription"
        @swiper="swiperInstance = $event"
      >
        <swiper-slide
          v-for="(certificate, index) in certificates"
          :key="`cert${index}`"
        >
          <certificate-page :pictures="certificate?.Picture"></certificate-page>
        </swiper-slide>
      </swiper>
    </div>
    <div
      class="side animation"
      v-observe-visibility="animation"
    >
      <slider-navigation
        :swiper="swiperInstance"
        :controledSwiper="controlledInstance"
        :isWhite="true"
        :counter="true"
        :counterText="counterText"
      />

      <div
        class="description"
        v-if="breakpoint === 'sm'"
      >
        <swiper
          @swiper="controlledInstance = $event"
          :speed="500"
          :effect="'fade'"
          :fade-effect="{crossFade: true}"
          :slides-per-view="1"
          :height-auto="'auto'"
          :allow-touch-move="false"
          :initialSlide="initialSlide"
        >
          <swiper-slide
            v-for="(certificate, index) in certificates"
            :key="`desc${index}`"
          >
            <div class="description-text">{{certificate?.Title}}</div>
          </swiper-slide>
        </swiper>
      </div>

      <div
        class="description"
        v-if="breakpoint !== 'sm'"
      >
        <template
          v-for="(certificate, index) in certificates"
          :key="index"
        >
          <transition name="fade">
            <div
              class="description-text "
              :class="{'is-active': index === currentIndex}"
              v-show="index === currentIndex"
            >{{certificate?.Title}}</div>
          </transition>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import SwiperCore, { EffectFade, Pagination, Controller } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/vue';
import getUrl from '@/tools/getUrl';
import { animationMixin } from '@/components/mixins/animation';
import SliderNavigation from '../../SliderNavigation.vue';
import CertificatePage from './CertificatePage.vue';

import 'swiper/swiper.scss';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'swiper/components/effect-fade/effect-fade.scss';

SwiperCore.use([EffectFade, Pagination, Controller]);

export default {
  name: 'CertificatesGallery',
  mixins: [animationMixin],
  data() {
    return {
      swiperInstance: {},
      controlledInstance: undefined,
      counterText: '',
    };
  },
  props: {
    initialSlide: {
      type: Number,
      default: 0,
    },
    certificates: {
      type: Array,
      required: true,
    },
  },
  components: {
    Swiper,
    SwiperSlide,
    SliderNavigation,
    CertificatePage,
  },
  computed: {
    currentIndex() {
      return this.swiperInstance.activeIndex;
    },
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
  },
  methods: {
    getUrl,
    renderCustom(sw, current, total) {
      const counterText = `${current} / ${total}`;
      this.counterText = counterText;
      return counterText;
    },
    switchDescription(sw) {
      if (this.controlledInstance) {
        this.controlledInstance.slideTo(sw.activeIndex);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.certificates-gallery {
  display: grid;
  width: 100%;
  grid-template-rows: 100%;
  box-sizing: border-box;

  @include wRule(
    column-gap,
    (
      xl: 85,
      lg: 78,
      md: 50,
      sm: 0,
    )
  );

  @include wRule(
    padding,
    (
      xl: 0 340,
      lg: 0 100,
      md: 0 70,
      sm: 0 72,
    )
  );

  @include wRule(
    grid-template-columns,
    (
      xl: 1fr 603 1fr,
      lg: 1fr 420 1fr,
      md: 1fr 420 1fr,
      sm: 100%,
    )
  );

  @include screen("sm", max) {
    display: flex;
    flex-direction: column;
  }

  .main {
    width: 100%;
    height: 100%;
    grid-column: 1/3;
    grid-row: 1 /2;
    overflow: hidden;

    @include screen("sm", max) {
      height: auto;
      flex: 1;
    }

    ::v-deep(.swiper-container) {
      width: 100%;
      height: 100%;
    }

    ::v-deep(.swiper-wrapper) {
      transition-timing-function: ease;
    }

    .swiper-slide {
      display: flex;
      pointer-events: none;
      height: auto;
      transition-property: transform, opacity;
      transition-timing-function: ease;

      &.swiper-slide-active {
        pointer-events: auto;
      }
    }

    &.animation {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.5s ease 0.5s, transform 0.5s ease 0.5s;
    }
  }

  ::v-deep(.swiper-pagination) {
    display: none;
  }

  .image {
    display: block;
    width: 100%;

    img {
      width: 100%;
    }
  }

  .side {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    grid-column: 3 /4;
    grid-row: 1 /2;

    @include screen("sm", max) {
      order: 3;
      flex: none;
    }

    &.animation {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.5s ease 0.6s, transform 0.5s ease 0.6s;
    }
  }

  .slider-navigation {
    order: 2;

    @include screen("sm", max) {
      align-self: center;
      flex: none;
    }

    @include wRule(
      margin,
      (
        xl: 40 0 0 -8,
        lg: 40 0 0 -5,
        md: 30 0 0 -5,
        sm: 48 0 0,
      )
    );

    ::v-deep(.prev) {
      @include wRule(
        margin-right,
        (
          xl: 40,
          lg: 45,
          md: 45,
          sm: 68,
        )
      );
    }
  }

  .description {
    position: relative;
    line-height: 1.4;
    overflow: hidden;
    width: 100%;

    ::v-deep(.swiper-container) {
      height: auto;
      width: 100%;
    }

    ::v-deep(.swiper-wrapper) {
      transition-timing-function: ease;
    }

    .swiper-slide {
      display: flex;
      align-items: flex-end;
      pointer-events: none;
      height: auto;
      transition-property: transform, opacity;
      transition-timing-function: ease;

      &.swiper-slide-active {
        pointer-events: auto;
      }
    }

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 20,
        md: 16,
        sm: 30,
      )
    );

    @include wRule(
      margin-bottom,
      (
        xl: -10,
        lg: -8,
        md: 0,
      )
    );

    @include wRule(
      margin-top,
      (
        sm: 35,
      )
    );
  }

  .description-text {
    // position: absolute;
    // bottom: 0;
    // left: 0;

    @include screen("sm", min) {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &.is-active {
      position: static;
    }

    &.fade-enter-active {
      transition: all 0.3s ease;
      transition-delay: 0.2s;
    }
    &.fade-leave-active {
      transition: all 0s ease;
    }
  }
}
</style>
