import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import ClickOutside from '@/tools/clickOutside';
import Vue3TouchEvents from 'vue3-touch-events';
import { ObserveVisibility } from 'vue-observe-visibility';
import VTooltip from 'v-tooltip';
// import VueScrollTo from 'vue-scrollto';

createApp(App).use(store).use(router).use(Vue3TouchEvents)
  .use(VTooltip)
  // .use(VueScrollTo)
  .directive('clickOutside', ClickOutside)
  .directive('observe-visibility', {
    beforeMount: (el, binding, node) => {
      const vnode = node;
      vnode.context = binding.instance;
      ObserveVisibility.bind(el, binding, vnode);
    },
    update: ObserveVisibility.update,
    unmounted: ObserveVisibility.unbind,
  })
  .mount('#app');
