<template>
  <div class="socials">
    <a
      v-for="(social, index) in socials"
      :key="index"
      :href="social.href"
      class="social"
      target="_blank"
    >
      <icon :name="social.icon" />
    </a>
  </div>
</template>

<script>
import Icon from './Icon.vue';

export default {
  name: 'Socials',
  components: {
    Icon,
  },
  computed: {
    socials() {
      return this.$store.getters.getSocials;
    },
  },
};
</script>

<style lang="scss" scoped>
.socials {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style: none;

  .social {
    display: block;
    flex: none;
    transition: opacity $ease-main;
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1);

    &:not(:first-child) {
      @include wRule(
        margin-left,
        (
          xl: 34,
          lg: 26,
          md: 25,
          sm: 47,
        )
      );
    }

    &:hover {
      @include screen("sm", min) {
        opacity: 0.8;
      }
    }

    @include wRule(
      width,
      (
        xl: 35,
        lg: 29,
        md: 28,
        sm: 37,
      )
    );
    @include wRule(
      height,
      (
        xl: 35,
        lg: 29,
        md: 28,
        sm: 37,
      )
    );

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &.scalein-enter-active {
    transition: all 0.2s ease;

    .social {
      transition: all 0.2s ease;
    }

    @for $i from 1 to 3 {
      &:nth-child(#{$i}) {
        .social {
          transition-delay: 0.05s + (0.05s * $i);
        }
      }
    }
  }
  &.scalein-leave-active {
    transition: all 0.2s ease;

    .social {
      transition: all 0.2s ease;
    }
  }

  &.scalein-enter-from,
  &.scalein-leave-to {
    opacity: 0;

    .social {
      transform: scale(0);
    }
  }
}
</style>
