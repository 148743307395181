<template>
  <button
    @click="changeLanguage"
    class="lang-button"
    type="button"
  >{{ targetLang }}</button>
</template>

<script>
export default {
  name: 'LangButton',
  computed: {
    currentLang() {
      return this.$store.getters.getLang;
    },
    targetLang() {
      return this.currentLang === 'ru' ? 'en' : 'ru';
    },
  },
  methods: {
    changeLanguage() {
      this.$store.dispatch('setLang', this.targetLang);
    },
  },
};
</script>

<style lang="scss" scoped>
.lang-button {
  display: inline-block;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1;
  transition: opacity $ease-main;
  color: currentColor;
  text-align: center;
  vertical-align: middle;
  transform-origin: 50% 50%;
  transform: scale(1) translate3d(0, 0, 0);

  @include wRule(
    margin-right,
    (
      xl: 52,
      lg: 42,
      md: 36,
      sm: 50,
    )
  );
  @include wRule(
    width,
    (
      xl: 30,
      lg: 25,
      md: 25,
      sm: 45,
    )
  );
  @include wRule(
    font-size,
    (
      xl: 18,
      lg: 16,
      md: 15,
      sm: 30,
    )
  );

  &::before {
    @include pseudo;

    font-size: inherit;
    left: 0;
    bottom: -0.04em;
    width: 100%;
    height: 0.1em;
    background-color: currentColor;
    transition: transform 0.4s ease;
    transform: scaleX(0);
    transform-origin: 0 50%;
  }

  @media (hover: hover) {
    &:hover {
      &::before {
        transform: scaleX(1);
      }
    }
  }

  &.scalein-enter-active {
    transition: all 0.2s ease;
    transition-delay: 0.2s;
  }

  &.scalein-leave-active {
    transition: all 0.2s ease;
  }

  &.scalein-enter-from,
  &.scalein-leave-to {
    transform: scale(0);
  }
}
</style>
