<template>
  <div
    class="popup"
    :class="{'no-horizontal-pad': isNoHorPad, 'no-scroll': noScroll, 'is-active': isModalShown}"
    @keyup.tab.stop
  >
    <div class="popup__overlay"></div>
    <close @click.prevent="closeModal"></close>
    <div class="popup__wrapper">
      <div class="popup__content">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
// import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { freeze, unfreeze } from '@/tools/blockScroll';
import Close from './Close.vue';

export default {
  name: 'Popup',
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
    isNoHorPad: {
      type: Boolean,
      default: false,
    },
    noScroll: {
      type: Boolean,
      default: false,
    },
  },
  components: { Close },
  emits: ['modalOpened', 'modalClosed'],
  computed: {
    isModalShown() {
      if (this.isShown) {
        this.showModal();
      }
      return this.isShown;
    },
  },
  methods: {
    closeModal() {
      this.$emit('modalClosed');
      // enableBodyScroll(this.$el);
      unfreeze();
    },
    showModal() {
      this.$emit('modalOpened');
      freeze();
      // disableBodyScroll(this.$el, {
      //   reserveScrollBarGap: true,
      // });
    },
  },
  // unmounted() {
  //   clearAllBodyScrollLocks();
  // },
};
</script>

<style lang="scss" scoped>
.popup {
  $b: &;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  color: color(basic-light);
  overflow: hidden;
  box-sizing: border-box;

  &.fade-slide-up-enter-active {
    transition: all 0.5s ease-out;
  }

  &.fade-slide-up-leave-active {
    transition: all 0.3s ease;
  }

  &.fade-slide-up-enter-from,
  &.fade-slide-up-leave-to {
    opacity: 0;
    // transform: translate3d(0, 50%, 0);
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    overflow: auto;

    @include wRule(
      padding-left,
      (
        xl: 240,
        lg: 165,
        md: 125,
        sm: 45,
      )
    );
    @include wRule(
      padding-right,
      (
        xl: 240,
        lg: 165,
        md: 125,
        sm: 45,
      )
    );

    #{$b}.no-horizontal-pad & {
      @include wRule(
        padding,
        (
          xl: 0 0,
        )
      );
    }

    #{$b}.no-scroll & {
      overflow: hidden;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-color: rgba(color(dark), 0.95);
  }

  &__content {
    position: static;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    max-height: 100%;
    width: 100%;

    @include wRule(
      padding-top,
      (
        xl: 80,
        lg: 80,
        md: 80,
        sm: 105,
      )
    );

    @include wRule(
      padding-bottom,
      (
        xl: 80,
        lg: 80,
        md: 80,
        sm: 105,
      )
    );
  }

  .close {
    position: absolute;
    z-index: 10;

    @include wRule(
      top,
      (
        xl: 42,
        lg: 30,
        md: 40,
        sm: 20,
      )
    );
    @include wRule(
      right,
      (
        xl: 96,
        lg: 65,
        md: 44,
        sm: 37,
      )
    );
  }
}
</style>
