export default {
  namespaced: true,
  state: {
    alliance: [
      {
        current: false,
        name: 'helicopters',
        titleIcon: 'title-helicopters',
        logo: 'logo-helicopters',
        describe: {
          en: 'SKYPRO HELICOPTERS Airlines specializes in the operation of helicopters with a maximum take-off weight of over 3,100 kg.',
          ru: 'Авиакомпания SKYPRO HELICOPTERS специализируется на эксплуатации вертолетов с максимальной взлетной массой более 3 100 кг.',
        },
        link: {
          en: {
            href: '#',
            name: 'Go to the site',
          },
          ru: {
            href: '#',
            name: 'Перейти на сайт',
          },
        },
        bgImage: {
          webp: {
            default: 'alliance/helicopter.webp',
            x2: 'alliance/helicopter@2x.webp',
          },
          jpg: {
            default: 'alliance/helicopter.jpg',
            x2: 'alliance/helicopter@2x.jpg',
          },
        },
      },
      {
        current: false,
        name: 'ground-services',
        titleIcon: 'title-ground-services',
        logo: 'logo-ground-services',
        describe: {
          en: 'SKYPRO GROUND SERVICES specialists offer transportation and accommodation of aviation personnel, as well as prompt delivery of in-flight meals.',
          ru: 'Cпециалисты компании SKYPRO GROUND SERVICES предлагают транспортировку и размещение авиационного персонала, а также оперативную доставку бортового питания.',
        },
        link: {
          en: {
            href: '#',
            name: 'Go to the site',
          },
          ru: {
            href: '#',
            name: 'Перейти на сайт',
          },
        },
        bgImage: {
          webp: {
            default: 'alliance/ground-service.webp',
            x2: 'alliance/ground-service@2x.webp',
          },
          jpg: {
            default: 'alliance/ground-service.jpg',
            x2: 'alliance/ground-service@2x.jpg',
          },
        },
      },
      {
        current: false,
        name: 'group',
        titleIcon: 'title-group',
        logo: 'logo-group',
        describe: {
          en: 'The main area of activity of SKYPRO GROUP is the ownership of shares in companies and businesses in the field of civil aviation.',
          ru: 'Основной сферой деятельности SKYPRO GROUP является владение акциями компаний и бизнесов в сфере гражданской авиации.',
        },
        link: {
          en: {
            href: '#',
            name: 'Go to the site',
          },
          ru: {
            href: '#',
            name: 'Перейти на сайт',
          },
        },
        bgImage: {
          webp: {
            default: 'alliance/group.webp',
            x2: 'alliance/group@2x.webp',
          },
          jpg: {
            default: 'alliance/group.jpg',
            x2: 'alliance/group@2x.jpg',
          },
        },
      },
      {
        current: true,
        anme: 'technics',
        titleIcon: 'title-technics',
        logo: 'logo',
        describe: {
          en: '-',
          ru: '-',
        },
        link: {
          en: {
            href: '#',
            name: 'Go to the site',
          },
          ru: {
            href: '#',
            name: 'Перейти на сайт',
          },
        },
        bgImage: false,
      },
      {
        current: false,
        name: 'real-estate',
        titleIcon: 'title-real-estate',
        logo: 'logo-real-estate',
        describe: {
          en: 'SKYPRO REAL ESTATE specializes in the management of commercial real estate.',
          ru: 'SKYPRO REAL ESTATE cпециализируется на управлении объектами коммерческой недвижимости.',
        },
        link: {
          en: {
            href: '#',
            name: 'Go to the site',
          },
          ru: {
            href: '#',
            name: 'Перейти на сайт',
          },
        },
        bgImage: {
          webp: {
            default: 'alliance/real-estate.webp',
            x2: 'alliance/real-estate@2x.webp',
          },
          jpg: {
            default: 'alliance/real-estate.jpg',
            x2: 'alliance/real-estate@2x.jpg',
          },
        },
      },
      {
        current: false,
        name: 'aviation',
        titleIcon: 'title-aviation',
        logo: 'logo-aviation',
        describe: {
          en: 'The main profile of SKYPRO AVIATION is the sale of aircraft, the organization of charter flights of any complexity, aviation consulting and aircraft management.',
          ru: 'Основной профиль SKYPRO AVIATION — продажа воздушных судов, организация чартерных рейсов любой сложности, авиационный консалтинг и менеджмент воздушных судов.',
        },
        link: {
          en: {
            href: '#',
            name: 'Go to the site',
          },
          ru: {
            href: '#',
            name: 'Перейти на сайт',
          },
        },
        bgImage: {
          webp: {
            default: 'alliance/aviation.webp',
            x2: 'alliance/aviation@2x.webp',
          },
          jpg: {
            default: 'alliance/aviation.jpg',
            x2: 'alliance/aviation@2x.jpg',
          },
        },
      },
    ],
  },
  getters: {
    getAllianceMembers(state) {
      return state.alliance;
    },
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
};
