// eslint-disable-next-line import/prefer-default-export
export const animationMixin = {
  data() {
    return {
      inView: false,
    };
  },
  methods: {
    animation(isVisible, entry, customClass) {
      const el = entry.target;
      const animationClass = customClass || 'is-animated';
      if (isVisible) {
        el.classList.add(animationClass);
        this.inView = true;
      } else {
        el.classList.remove(animationClass);
        this.inView = false;
      }
    },
  },
};

// export const gsapAnimationMixin = {
//   methods: {
//     gsapAnimation(isVisible, entry, targetObjectsSelector) {
//       const el = entry.target;
//       const targets = el.querySelectorAll(targetObjectsSelector);
//       if (isVisible) {
//       } else {
//       }
//     },
//   },
// };
