<template>
  <button
    class="close"
    type="button"
  >
    <icon name="cross"></icon>
  </button>
</template>

<script>
import Icon from './Icon.vue';

export default {
  name: 'Close',
  components: {
    Icon,
  },
};
</script>

<style lang="scss">
.close {
  display: block;
  box-sizing: border-box;
  color: currentColor;
  transition: color $ease-main;

  @include wRule(
    width,
    (
      xl: 58,
      lg: 52,
      md: 46,
      sm: 54,
    )
  );
  @include wRule(
    height,
    (
      xl: 58,
      lg: 52,
      md: 46,
      sm: 54,
    )
  );

  @include wRule(
    padding,
    (
      xl: 10,
      lg: 10,
      md: 10,
      sm: 10,
    )
  );

  @media (hover: hover) {
    &:hover {
      color: color(main);
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }
}
</style>
