export default function breakpointWatcher(instance) {
  function setBreakpoint() {
    const sizes = {
      xl: 1920,
      lg: 1484,
      md: 1080,
      sm: 768,
    };

    let breakpoint = 'xl';

    const wW = window.innerWidth;

    if (wW >= sizes.lg) {
      breakpoint = 'xl';
    }
    if (wW < sizes.lg && wW >= sizes.md) {
      breakpoint = 'lg';
    }
    if (wW < sizes.md && wW >= sizes.sm) {
      breakpoint = 'md';
    }
    if (wW < sizes.sm) {
      breakpoint = 'sm';
    }

    instance.$store.commit('setWindowWidth', wW);
    instance.$store.dispatch('setBreakpoint', breakpoint);
  }

  setBreakpoint();

  window.addEventListener('resize', setBreakpoint);
}
