<template>
  <div class="certificate-page">
    <div class="thumbnails">
      <div
        class="thumbnail"
        :class="{'is-active': currentPage === thumbId}"
        v-for="(thumb, thumbId) in pictures"
        :key="`page${thumbId}`"
        @click.prevent="currentPage = thumbId"
      >
        <img
          :src="getUrl(thumb?.url)"
          :alt="`page${thumbId}`"
        >
      </div>
    </div>
    <div class="page">
      <transition
        name="fade"
        mode="out-in"
      >
        <picture
          class="image"
          :key="`page${currentPage}`"
        >
          <img
            :src="getUrl(pictures[currentPage]?.url)"
            :alt="`page${currentPage}`"
          >
        </picture>
      </transition>
    </div>
  </div>
</template>

<script>
import getUrl from '@/tools/getUrl';

export default {
  name: 'CertificatePage',
  props: {
    pictures: {
      type: Array,
    },
  },
  data() {
    return {
      currentPage: 0,
    };
  },
  methods: {
    getUrl,
  },
};
</script>

<style lang="scss" scoped>
.certificate-page {
  display: flex;
  width: 100%;

  @include rule(
    flex-direction,
    (
      xl: row,
      sm: column,
    )
  );

  .thumbnails {
    display: flex;
    justify-content: flex-start;
    flex: 1;

    @include rule(
      flex-direction,
      (
        xl: column,
        sm: row,
      )
    );
    @include rule(
      align-items,
      (
        xl: flex-end,
        sm: center,
      )
    );

    @include screen("sm", max) {
      order: 2;
      flex: none;
      margin-top: pxtovw(25, sm);
    }

    &:not(:last-child) {
      @include wRule(
        margin-right,
        (
          xl: 85,
          lg: 78,
          md: 45,
          sm: 0,
        )
      );
    }
  }

  .thumbnail {
    display: block;
    cursor: pointer;
    height: auto;
    box-sizing: border-box;
    border-style: solid;
    border-color: transparent;
    transition: border-color 0.2s ease;

    @include wRule(
      width,
      (
        xl: 97,
        lg: 72,
        md: 72,
        sm: 72,
      )
    );

    @include wRule(
      border-width,
      (
        xl: 3,
        lg: 3,
        md: 3,
        sm: 3,
      )
    );

    &:not(:last-child) {
      @include wRule(
        margin-bottom,
        (
          xl: 20,
          lg: 12,
          md: 12,
          sm: 0,
        )
      );

      @include wRule(
        margin-right,
        (
          sm: 24,
        )
      );
    }

    img {
      width: 100%;
      height: auto;
    }

    &.is-active {
      border-color: color(main);
    }
  }

  .page {
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;

    @include wRule(
      width,
      (
        xl: 560,
        lg: 420,
        md: 420,
      )
    );

    @include screen("sm", max) {
      width: 100%;
      flex: 1;
    }
  }

  .image {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    max-height: 100%;

    &.fade-enter-active {
      transition: all 0.3s ease;
    }

    &.fade-leave-active {
      transition: all 0.2s ease;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>
