<template>
  <div :class="['site-navigation', {'is-active': isActive}]">
    <div class="site-navigation__header">
      <aog-button
        v-if="breakpoint !== 'sm'"
        :btnAnimation="isShown"
        animationName="fade"
        @hide-nav="closeNavigation"
      >{{ navigationData?.AOGButtonText }}</aog-button>
      <!-- <transition name="scalein">
        <lang-button
          v-show="isShown"
          @click="closeNavigation"
        />
      </transition> -->
      <!-- <transition name="scalein">
        <socials v-show="isShown" />
      </transition> -->
      <transition name="scalein-rotate">
        <close
          @click="closeNavigation"
          v-show="isShown"
        ></close>
      </transition>
    </div>
    <div class="site-navigation__content">
      <nav>
        <transition-group
          name="slide-fade"
          tag="div"
        >
          <div
            v-show="isShown"
            class="site-navigation__item"
            v-for="(item, index) in navigation"
            :key="`nav${index}`"
          >
            <navigation-link
              v-if="!item.popup"
              :linkData="item"
              @hide-nav="closeNavigation"
            />
            <navigation-link-popup
              v-if="item.popup"
              :linkData="item"
              @hide-nav="closeNavigation"
            />
          </div>
        </transition-group>
      </nav>
      <div class="site-navigation__contacts">
        <aog-button
          v-if="breakpoint === 'sm'"
          :btnAnimation="isShown"
          animationName="slide-fade"
          @hide-nav="closeNavigation"
        />
        <ul>
          <transition-group
            name="slide-fade"
            tag="li"
          >
            <li
              v-show="isShown"
              v-for="(contact, index) in contacts"
              :key=index
            >
              <contact-link v-bind="contact" />
            </li>
          </transition-group>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Close from './Close.vue';
import AogButton from './PageHeader/components/AogButton.vue';
// import LangButton from './PageHeader/components/LangButton.vue';
// import Socials from './Socials.vue';
import NavigationLink from './NavigationLink.vue';
import NavigationLinkPopup from './NavigationLinkPopup.vue';
import ContactLink from './ContactLink.vue';

export default {
  name: 'SiteNavigation',
  components: {
    Close,
    AogButton,
    // LangButton,
    // Socials,
    NavigationLink,
    NavigationLinkPopup,
    ContactLink,
  },
  emits: ['hideNavigation'],
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    isShown: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
    navigation() {
      return this.$store.getters.getNavigation.slice(0, -1);
    },
    contacts() {
      return this.$store.getters.getContacts;
    },
    navigationData() {
      return this.$store.getters.getNavigationData;
    },
  },
  methods: {
    closeNavigation() {
      this.$emit('hideNavigation');
    },
  },
};
</script>

<style lang="scss" scoped>
.site-navigation {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  top: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  background-color: color(dark);
  color: color(basic-light);
  pointer-events: none;
  overflow: hidden;
  z-index: 25;

  @include screen("sm", max) {
    overflow: auto;
    max-height: 100%;
  }

  @include wRule(
    width,
    (
      xl: 843, // -82
      lg: 616, // -67
      md: 579, // -61
      sm: 100%,
    )
  );

  &.is-active {
    pointer-events: auto;
  }

  &__header {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    flex: none;
    width: 100%;
    justify-content: flex-end;

    // @include rule(
    //   justify-content,
    //   (
    //     xl: flex-start,
    //     sm: flex-end,
    //   )
    // );

    @include wRule(
      padding,
      (
        xl: 42 98 0 225,
        lg: 30 65 0 125,
        md: 42 55 0 125,
        sm: 23 37 23 92,
      )
    );
  }

  .socials {
    @include rule(
      margin-right,
      (
        xl: auto,
        sm: 0,
      )
    );
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;

    @include screen("sm", max) {
      flex: none;
      overflow: hidden;
    }

    @include wRule(
      padding-right,
      (
        xl: 108,
        lg: 75,
        md: 75,
        sm: 92,
      )
    );
    @include rule(
      padding-top,
      (
        xl: pxtovh(90, xl),
        lg: pxtovh(40, lg),
        md: pxtovh(40, md),
        sm: pxtovw(82, sm),
      )
    );

    @include rule(
      padding-bottom,
      (
        xl: pxtovh(90, xl),
        lg: pxtovh(40, lg),
        md: pxtovh(40, md),
        sm: pxtovw(140, sm),
      )
    );

    @include wRule(
      padding-left,
      (
        xl: 225,
        lg: 125,
        md: 125,
        sm: 92,
      )
    );
  }

  nav {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }

  &__item {
    display: block;
    letter-spacing: -0.02em;
    line-height: 1.6;
    font-weight: 400;

    @include wRule(
      font-size,
      (
        xl: 35,
        lg: 30,
        md: 30,
        sm: 45,
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 21,
          lg: 7,
          md: 7,
          sm: 9,
        )
      );
    }
  }

  &__contacts {
    display: block;
    width: 100%;

    &:not(:first-child) {
      @include rule(
        margin-top,
        (
          xl: pxtovh(60, xl),
          lg: pxtovh(40, lg),
          md: pxtovh(40, md),
          sm: pxtovw(105, sm),
        )
      );
    }

    ul {
      display: block;
      list-style: none;

      &:not(:first-child) {
        @include wRule(
          margin-top,
          (
            xl: pxtovh(40, xl),
            lg: pxtovh(40, lg),
            md: pxtovh(40, md),
            sm: pxtovw(125, sm),
          )
        );
      }
    }

    li {
      display: block;

      &:not(:first-child) {
        @include wRule(
          margin-top,
          (
            xl: 15,
            lg: 15,
            md: 8,
            sm: 15,
          )
        );
      }

      &.slide-fade-enter-active {
        @for $i from 1 to 20 {
          &:nth-child(#{$i}) {
            transition-delay: 0.4s + (0.05s * $i);
          }
        }
      }
    }
  }

  .close {
    color: color(basic-light);
    transform-origin: 50% 50%;
    transform: scale(1) rotate(0);

    &:not(:first-child) {
      @include wRule(
        margin-left,
        (
          xl: 52,
          lg: 38,
          md: 38,
          sm: 55,
        )
      );
    }

    &.scalein-rotate-enter-active {
      transition: all 0.2s ease;
    }

    &.scalein-rotate-leave-active {
      transition: all 0.2s ease;
    }

    &.scalein-rotate-enter-from,
    &.scalein-rotate-leave-to {
      transform: scale(0) rotate(90deg);
    }
  }
}
</style>
