<template>
  <div
    class="feature animation"
    v-observe-visibility="animation"
  >
    <div class="feature__icon">
      <img
        :src="getUrl(iconUrl)"
        class="icon"
      />
    </div>
    <p v-html="text"></p>
  </div>
</template>

<script>
import getUrl from '@/tools/getUrl';
import { animationMixin } from '@/components/mixins/animation';

export default {
  name: 'Feature',
  mixins: [animationMixin],
  props: {
    iconUrl: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  methods: {
    getUrl,
  },
};
</script>

<style lang="scss" scoped>
.feature {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.animation {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  &.is-animated {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 0.5s ease, transform 0.5s ease;

    @for $i from 1 to 10 {
      &:nth-child(#{$i}) {
        transition-delay: 0.6s + 0.1s * $i;
      }
    }
  }

  &__icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    flex: none;

    .icon {
      display: block;
      height: 100%;
    }

    @include wRule(
      height,
      (
        xl: 55,
        lg: 40,
        md: 34,
        sm: 55,
      )
    );
  }

  p {
    line-height: 1.2;
    font-weight: 700;

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 18,
        md: 16,
        sm: 25,
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 35,
          lg: 26,
          md: 20,
          sm: 30,
        )
      );
    }
  }

  /*
  Horizontal
   */
  &_horizontal {
    @include screen("sm", min) {
      flex-direction: row;
      align-items: flex-start;
    }

    .feature__icon {
      @include screen("sm", min) {
        justify-content: center;
        align-items: flex-start;

        .icon {
          width: 100%;
          height: 100%;
        }

        @include wRule(
          height,
          (
            xl: 54,
            lg: 38,
            md: 36,
            sm: auto,
          )
        );
        @include wRule(
          width,
          (
            xl: 62,
            lg: 44,
            md: 40,
            sm: auto,
          )
        );
      }
    }

    p {
      @include screen("xl", min) {
        max-width: 200px;
      }

      &:not(:first-child) {
        @include wRule(
          margin-left,
          (
            xl: 38,
            lg: 27,
            md: 27,
            sm: 0,
          )
        );
        @include wRule(
          margin-top,
          (
            xl: 0,
            sm: 28,
          )
        );
      }
    }
  }
}
</style>
