<template>
  <component
    :is="tag"
    class="v-button"
  >
    <div class="v-button__wrapper">
      <slot name="start"></slot>
      <span class="v-button__text">
        <slot></slot>
      </span>
      <slot name="end"></slot>
    </div>
  </component>
</template>

<script>
export default {
  name: 'VButton',
  props: {
    tag: {
      type: String,
      default: 'button',
    },
  },
};
</script>

<style lang="scss" scoped>
.v-button {
  $b: &;
  position: relative;
  display: inline-block;
  text-align: center;
  font-weight: 700;
  border-style: solid;
  border-color: currentColor;
  line-height: 1;
  box-sizing: border-box;
  color: currentColor;
  @include wRule(
    padding,
    (
      xl: 0 19,
      lg: 0 17,
      sm: 0 15,
    )
  );
  @include wRule(
    border-width,
    (
      xl: 3,
      lg: 3,
      md: 3,
      sm: 3,
    )
  );
  @include wRule(
    height,
    (
      xl: 52,
      lg: 44,
      md: 42,
      sm: 93,
    )
  );
  @include wRule(
    font-size,
    (
      xl: 20,
      lg: 18,
      md: 15,
      sm: 30,
    )
  );
  &::before {
    @include pseudo;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: currentColor;
    opacity: 0;
    transition: opacity $ease-main;
    z-index: -1;
  }
  @media (hover: hover) {
    &:hover {
      &::before {
        opacity: 0.05;
      }
    }
  }
  &:focus {
    &::before {
      opacity: 0.1;
    }
  }
  &__wrapper {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
  }
  &__text {
    margin-top: 0;
    margin-bottom: 0;
    letter-spacing: -0.02em;
  }
  ::v-deep(.icon_pdf) {
    flex: none;

    @include wRule(
      width,
      (
        xl: 15,
        lg: 12,
        md: 12,
        sm: 22,
      )
    );

    @include wRule(
      margin-right,
      (
        xl: 20,
        lg: 15,
        md: 14,
        sm: 12,
      )
    );
  }

  &.thin {
    @include wRule(
      border-width,
      (
        xl: 1,
        lg: 1,
        md: 1,
        sm: 1,
      )
    );
    @include wRule(
      padding,
      (
        xl: 0 10,
        lg: 0 10,
        md: 0 10,
        sm: 0 26 0 10,
      )
    );

    @include wRule(
      height,
      (
        xl: 50,
        lg: 42,
        md: 38,
        sm: 66,
      )
    );
    #{$b} {
      &__wrapper {
        justify-content: flex-start;

        // @include screen("sm", max) {
        //   justify-content: center;
        // }
      }
      &__text {
        font-weight: 400;
        white-space: nowrap;

        @include wRule(
          font-size,
          (
            xl: 20,
            lg: 18,
            md: 16,
            sm: 30,
          )
        );

        @include screen("sm", max) {
          margin: 0 auto;
        }
      }
    }
  }
}
</style>
