<template>
  <a
    :href="href"
    class="contact-link"
  >
    <icon :name="icon"></icon>
    <span>{{name || href}}</span>
  </a>
</template>

<script>
import Icon from './Icon.vue';

export default {
  name: 'ContactLink',
  components: { Icon },
  props: {
    icon: {
      type: String,
    },
    href: {
      type: String,
      required: true,
    },
    name: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-link {
  $b: &;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 1.28;
  letter-spacing: -0.02em;

  @include wRule(
    font-size,
    (
      xl: 20,
      lg: 18,
      md: 16,
      sm: 30,
    )
  );

  .icon {
    @include wRule(
      width,
      (
        xl: 34,
        lg: 28,
        md: 25,
        sm: 48,
      )
    );
    @include wRule(
      height,
      (
        xl: 34,
        lg: 28,
        md: 25,
        sm: 48,
      )
    );

    &:not(:last-child) {
      @include wRule(
        margin-right,
        (
          xl: 21,
          lg: 19,
          md: 13,
          sm: 43,
        )
      );
    }
  }

  span {
    position: relative;
    display: inline-block;
    white-space: nowrap;

    &::before {
      @include pseudo;

      font-size: inherit;
      left: 0;
      bottom: -0.04em;
      width: 100%;
      height: 0.1em;
      background-color: currentColor;
      transition: transform 0.4s ease;
      transform: scaleX(0);
      transform-origin: 0 50%;
    }
  }

  &:hover {
    span {
      &::before {
        transform: scaleX(1);
      }
    }
  }
}
</style>
