<template>
  <section class="page-section">
    <slot name="background"></slot>
    <div class="page-section__content">
      <slot name="header"></slot>
      <slot name="content"></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PageSection',
};
</script>

<style lang="scss">
.page-section {
  position: relative;
  display: flex;
  width: 100%;
  background-color: color(dark);
  color: color(basic-light);
  box-sizing: border-box;

  @include screen("sm", min) {
    transform: scale(1);
    transform-origin: 100% 100%;
  }

  &.is-active-animation {
    @include screen("sm", min) {
      animation: scaleIn 0.5s cubic-bezier(0.42, -0.01, 0.84, 0.64);
      z-index: 2;
    }
  }

  &.is-active-reverse-animation {
    @include screen("sm", min) {
      animation: scale-shift 0.5s cubic-bezier(0.42, -0.01, 0.84, 0.64);
      z-index: 2;
    }
  }

  &.is-leave-animation {
    @include screen("sm", min) {
      animation: shiftDown 0.5s cubic-bezier(0.42, -0.01, 0.84, 0.64);
      z-index: 1;
    }
  }

  &.is-enter-animation {
    @include screen("sm", min) {
      animation: shiftUp 0.5s cubic-bezier(0.42, -0.01, 0.84, 0.64);
      z-index: 1;
    }

    .start-init & {
      @include screen("sm", min) {
        animation-duration: 0s;
      }
    }
  }

  &.fullpage {
    height: 100vh;

    @include screen("md", max) {
      min-height: 100vh;
      height: auto;
    }

    @include screen("sm", max) {
      min-height: 0;
      height: auto;
    }
  }

  h1 {
    display: block;
    font-weight: 700;
    letter-spacing: -0.02em;
    line-height: 1.2;

    @include wRule(
      font-size,
      (
        xl: 95,
        lg: 65,
        md: 60,
        sm: 75,
      )
    );

    &.animation {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.5s ease 0.5s, transform 0.5s ease 0.5s;
    }
  }

  h2 {
    display: block;
    font-weight: 700;
    letter-spacing: -0.02em;
    line-height: 1.2;

    @include wRule(
      font-size,
      (
        xl: 85,
        lg: 65,
        md: 55,
        sm: 75,
      )
    );

    + p {
      @include wRule(
        margin-top,
        (
          xl: 88,
          lg: 62,
          md: 62,
          sm: 48,
        )
      );
    }

    &.animation {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.5s ease 0.5s, transform 0.5s ease 0.5s;
    }
  }

  h3 {
    display: block;
    font-weight: 700;
    letter-spacing: -0.02em;
    line-height: 1.2;

    @include wRule(
      font-size,
      (
        xl: 65,
        lg: 50,
        md: 45,
        sm: 65,
      )
    );

    + p {
      @include wRule(
        margin-top,
        (
          xl: 90,
          lg: 70,
          md: 45,
          sm: 52,
        )
      );
    }

    &.animation {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.5s ease 0.5s, transform 0.5s ease 0.5s;
    }
  }

  h4 {
    display: block;
    font-weight: 700;
    letter-spacing: -0.02em;
    line-height: 1.2;

    @include wRule(
      font-size,
      (
        xl: 45,
        lg: 35,
        md: 30,
        sm: 45,
      )
    );

    &.animation {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.5s ease 0.5s, transform 0.5s ease 0.5s;
    }
  }

  p {
    display: block;
    letter-spacing: -0.02em;
    font-weight: 400;
    line-height: 1.6;

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 18,
        md: 16,
        sm: 30,
      )
    );

    &.animation {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.5s ease 0.7s, transform 0.5s ease 0.7s;
    }
  }

  &__content {
    position: relative;
    display: block;
    width: 100%;
    z-index: 2;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: flex-start;

    @include screen("sm", max) {
      flex-wrap: wrap;
    }
  }

  &_pale {
    color: color(bg);
  }

  &_light {
    background-color: color(bg);
    color: color(basic);
  }

  .bg-image,
  .bg-video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  .block-link {
    &_large {
      position: absolute;
      bottom: 0;
      right: 0;

      @include rule(
        min-height,
        (
          xl: 29.6vh,
          lg: 25.7vh,
          md: 25.26vh,
          sm: 0,
        )
      );
      @include rule(
        min-width,
        (
          xl: 30.5%,
          lg: 36.38%,
          md: 36.23%,
          sm: 0,
        )
      );

      @include screen("sm", max) {
        position: relative;
        right: auto;
        bottom: auto;
        margin-left: 7.03125vw;
        width: calc(100% - 7.03125vw);
      }

      @include screen("xl", min) {
        min-height: 322px;
      }

      @media screen and (max-height: 760px) and (min-width: 768px) {
        min-width: 30%;
      }

      &:not(:first-child) {
        @include rule(
          margin-top,
          (
            sm: pxtovw(123, sm),
          )
        );
      }
    }
  }
}
</style>
