<template>
  <div
    class="slider-arrows"
    :class="[themeClass, { 'has-text': text, 'is-hide': isLock}]"
  >
    <!-- v-observe-visibility="{ callback: animation}" -->
    <div
      v-if="text"
      class="text"
    >
      <span>{{ text }}</span>
    </div>
    <button
      type="button"
      class="arrow prev"
      :disabled="swiper?.isBeginning"
      @click="swiper?.slidePrev()"
    >
      <Icon
        name="next"
        class="icon"
      />
    </button>
    <button
      type="button"
      class="arrow"
      :disabled="swiper?.isEnd"
      @click="swiper?.slideNext()"
    >
      <Icon
        name="next"
        class="icon"
      />
    </button>
  </div>
</template>

<script>
import Icon from '@/components/Icon.vue';
import { animationMixin } from '@/components/mixins/animation';

export default {
  name: 'SliderArrows',
  components: { Icon },
  mixins: [animationMixin],
  props: {
    theme: {
      type: String,
    },
    swiper: {
      // eslint-disable-next-line no-undef
      type: Object,
      required: true,
    },
    text: {
      type: [String, Number],
    },
  },
  computed: {
    themeClass() {
      return this.theme ? `theme-${this.theme}` : '';
    },
    isLock() {
      return this.swiper?.isLocked;
    },
  },
};
</script>

<style lang="scss" scoped>
.slider-arrows {
  display: flex;
  align-items: center;
  color: color(bg);
  position: relative;

  &.is-hide {
    display: none;
  }

  &.animation {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  &.is-animated {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 0.5s ease 0.8s, transform 0.5s ease 0.8s;
  }

  .arrow {
    display: block;
    transform-origin: 50% 50%;
    color: inherit;
    transition: color $ease-main, opacity $ease-main;
    font-size: 0;
    line-height: 0;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: -100%;
      top: -100%;
      right: -100%;
      bottom: -100%;
    }

    &.prev {
      transform: rotate(180deg);
    }

    &:hover {
      @include screen("sm", min) {
        color: color(main);
      }
    }

    &[disabled] {
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
    }

    &:not(:last-child) {
      @include wRule(
        margin-right,
        (
          xl: 60,
          lg: 43,
          md: 68,
          sm: 90,
        )
      );
    }
  }

  .icon {
    display: block;

    @include wRule(
      height,
      (
        xl: 43,
        lg: 31,
        md: 32,
        sm: 43,
      )
    );
  }

  &.has-text .arrow {
    &:not(:last-child) {
      @include wRule(
        margin-right,
        (
          xl: 83,
          lg: 59,
          md: 68,
          sm: 90,
        )
      );
    }
  }

  /*
  Themes
   */
  &.theme-dark {
    color: color(basic);

    .arrow[disabled] {
      opacity: 0.2;
    }
  }

  &.theme-white {
    color: color(basic-light);
  }

  .text {
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1;
    font-weight: 700;

    @include wRule(
      font-size,
      (
        xl: 30,
        lg: 25,
        md: 20,
        sm: 45,
      )
    );
  }
}
</style>
