<template>
  <div class="aog">
    <h3
      v-observe-visibility="animation"
      class="aog__title animation"
      v-html="pageContent?.Title"
    ></h3>
    <p
      v-observe-visibility="animation"
      class="aog__describe animation"
      v-html="pageContent?.Text"
    ></p>
    <p
      v-observe-visibility="animation"
      class="aog__worktime animation"
    >{{pageContent?.BigSubtitle}}</p>
    <a
      v-observe-visibility="animation"
      :href="`tel:${pageContent?.Phone?.replace(/ /gi, '')}`"
      class="aog__contacts animation"
    >{{ pageContent?.Phone }}</a>
    <a
      v-observe-visibility="animation"
      :href="`mailto:${pageContent?.Email}`"
      class="aog__mail animation"
    >{{pageContent?.Email}}</a>
  </div>
</template>

<script>
import { animationMixin } from '@/components/mixins/animation';
import api from '@/tools/api';

export default {
  name: 'Aog',
  mixins: [animationMixin],
  data() {
    return {
      pageContent: {},
    };
  },
  computed: {
    currentLang() {
      return this.$store.getters.getLang;
    },
  },
  watch: {
    currentLang() {
      this.getPageContent();
    },
  },
  beforeMount() {
    this.getPageContent();
  },
  methods: {
    async getPageContent() {
      this.pageContent = await api('aog', true);
    },
  },
};
</script>

<style lang="scss" scoped>
.aog {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  line-height: 1.28;

  &__title {
    font-weight: 700;

    @include wRule(
      font-size,
      (
        xl: 150,
        lg: 100,
        md: 100,
        sm: 150,
      )
    );

    &.animation {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.3s ease 0.4s, transform 0.3s ease 0.4s;
    }
  }

  &__describe {
    @include wRule(
      max-width,
      (
        xl: 400,
        lg: 350,
        md: 350,
        sm: 100%,
      )
    );
    @include wRule(
      font-size,
      (
        xl: 24,
        lg: 18,
        md: 18,
        sm: 30,
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 0,
          sm: 18,
        )
      );
    }

    &.animation {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.3s ease 0.5s, transform 0.3s ease 0.5s;
    }
  }

  &__worktime {
    @include wRule(
      font-size,
      (
        xl: 100,
        lg: 80,
        md: 80,
        sm: 100,
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 40,
          lg: 40,
          md: 26,
          sm: 54,
        )
      );
    }

    &.animation {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.3s ease 0.6s, transform 0.3s ease 0.6s;
    }
  }

  &__contacts {
    position: relative;
    display: inline-block;
    cursor: pointer;

    @include wRule(
      font-size,
      (
        xl: 50,
        lg: 30,
        md: 30,
        sm: 50,
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 46,
          lg: 34,
          md: 38,
          sm: 48,
        )
      );
    }

    &::before {
      @include pseudo;

      font-size: inherit;
      bottom: -0.04em;
      left: 0;
      height: 0.1em;
      width: 100%;
      background-color: currentColor;
      transform-origin: 0 50%;
      transform: scaleX(0);
      transition: transform 0.4s ease;
    }

    &:hover {
      &::before {
        @include screen("sm", min) {
          transform: scaleX(1);
        }
      }
    }

    &.animation {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.3s ease 0.7s, transform 0.3s ease 0.7s;
    }
  }

  &__mail {
    position: relative;

    @include wRule(
      font-size,
      (
        xl: 24,
        lg: 18,
        md: 18,
        sm: 30,
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 46,
          lg: 33,
          md: 38,
          sm: 66,
        )
      );
    }

    &::before {
      @include pseudo;

      font-size: inherit;
      bottom: -0.04em;
      left: 0;
      height: 0.1em;
      width: 100%;
      background-color: currentColor;
      transform-origin: 0 50%;
      transform: scaleX(0);
      transition: transform 0.4s ease;
    }

    &:hover {
      &::before {
        @include screen("sm", min) {
          transform: scaleX(1);
        }
      }
    }

    &.animation {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.3s ease 0.8s, transform 0.3s ease 0.8s;
    }
  }
}
</style>
