<template>
  <div
    class="bg-image"
    :class="{'no-gradient': noGradient}"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BgImage',
  props: {
    noGradient: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.bg-image {
  position: relative;
  display: block;
  transform: scale(1);
  overflow: hidden;

  picture {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    z-index: 1;

    img {
      @include fill-img;
    }
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;

    @include fill-img;
  }

  &::after {
    position: absolute;
    content: "";
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-repeat: no-repeat;
    background-image: linear-gradient(
      180deg,
      rgba(20, 24, 31, 0) 0%,
      rgba(20, 24, 31, 0.85) 100%
    );
  }

  &.no-gradient {
    &::after {
      display: none;
    }
  }
}
</style>
