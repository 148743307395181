export default function scrollObserver(instance) {
  let lastScrollTop = 0;
  const inst = instance;

  function handlerScroll() {
    const sT = window.pageYOffset;
    inst.pageOnTop = (sT === 0);
    inst.pageScrolledDown = sT > lastScrollTop;
    lastScrollTop = sT <= 0 ? 0 : sT;
  }

  window.addEventListener('scroll', handlerScroll, { passive: true });
}
