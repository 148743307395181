import { createStore } from 'vuex';
import alliance from './alliance';

export default createStore({
  state: {
    lang: 'ru',
    currentBreakpoint: 'xl',
    windowWidth: 0,
    navigationData: {},
    navigation: [],
    socials: [],
    contacts: [],
    navigationColors: {},
  },
  getters: {
    getLang(state) {
      return state.lang;
    },
    getNavigation(state) {
      return state.navigation;
    },
    getSocials(state) {
      return state.socials;
    },
    getBreakpoint(state) {
      return state.currentBreakpoint;
    },
    getContacts(state) {
      return state.contacts;
    },
    getNavigationData(state) {
      return state.navigationData;
    },
    getNavigationColors(state) {
      return state.navigationColors;
    },
  },
  mutations: {
    setLang(state, value) {
      state.lang = value;
    },
    setBreakpoint(state, value) {
      state.currentBreakpoint = value;
    },
    setWindowWidth(state, value) {
      state.windowWidth = value;
    },
    setContacts(state, value) {
      state.contacts = value;
    },
    setNavigation(state, data) {
      state.navigationData = data;
      state.navigation = [
        {
          title: data?.NamePageMain,
          href: '/',
          popup: false,
        },
        {
          title: data?.NamePageAbout,
          href: '/about',
          popup: false,
        },
        {
          title: data?.NamePageServices,
          href: '/services',
          popup: false,
        },
        {
          title: data?.NamePageCertificates,
          popup: 'CertificatesPopup',
        },
        {
          title: data?.NamePageBase,
          href: '/base',
          popup: false,
        },
        // {
        //   title: data?.NamePageAlliance,
        //   popup: 'alliance',
        // },
        {
          title: data?.NamePageInstruments,
          href: '/tools',
          popup: false,
        },
        {
          title: data?.NamePageContacts,
          href: '/contacts',
          popup: false,
        },
        {
          title: data?.NamePagePolicy,
          href: '/privacy-policy',
          popup: false,
        },
      ];
    },
    setSocials(state, value) {
      state.socials = value;
    },
    setNavigationColors(state, value) {
      state.navigationColors = value;
    },
  },
  actions: {
    setLang(context, value) {
      context.commit('setLang', value);
    },
    setBreakpoint(context, value) {
      context.commit('setBreakpoint', value);
    },
  },
  modules: {
    alliance,
  },
});
