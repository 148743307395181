<template>
  <div class="page-container">
    <home-page
      @page-slider-scroll-down="pageScrollDown"
      @page-slider-scroll-up="pageScrollUp"
      @page-slider-reach-footer="$emit('pageSliderReachFooter')"
      @page-slider-go-away-footer="$emit('pageSliderGoAwayFooter')"
    />
    <page-footer v-if="breakpoint === 'sm' || breakpoint === 'md'"></page-footer>
  </div>
</template>

<script>
import HomePage from '../components/HomePage.vue';
import PageFooter from '@/components/PageFooter.vue';

export default {
  components: { HomePage, PageFooter },
  name: 'Index',
  emits: [
    'pageSliderScrollDown',
    'pageSliderScrollUp',
    'pageSliderReachFooter',
    'pageSliderGoAwayFooter',
  ],
  methods: {
    pageScrollDown(slideNumber) {
      this.$emit('pageSliderScrollDown', slideNumber);
    },
    pageScrollUp(slideNumber) {
      this.$emit('pageSliderScrollUp', slideNumber);
    },
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
  },
};
</script>
